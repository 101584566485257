/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createAction } from '@reduxjs/toolkit'
import { RouteComponentProps } from 'react-router-dom'
import { DeferredAction } from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'
import { UUID } from '@/utilities/codec'

import { EntityName } from './source.constants'

// ---------------------------------------------------------------------------

// Note: calling this createImplant instead of createSource to plan for the future where
// we can create sources other than implant
export const createImplant = createAction(
  `${EntityName}/create-implant`,
  (payload: Codecs.CreateImplantPayload, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const startPollingImplantBakeStatus = createAction(
  `${EntityName}/start-polling-implant-bake-status`,
  (payload: { id: UUID }, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const stopPollingImplantBakeStatus = createAction(`${EntityName}/stop-polling-implant-bake-status`)

export const editDNS = createAction(
  `${EntityName}/edit-dns`,
  (payload: { id: UUID; dns_override: string }, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const deleteSource = createAction(
  `${EntityName}/delete-source`,
  (payload: UUID, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const fetchSources = createAction(
  `${EntityName}/fetch-many`,
  (
    payload: RouteComponentProps['location']['search'],
    deferred: DeferredAction['meta']['deferred'],
    persist?: boolean,
  ) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)

export const fetchActiveSources = createAction(
  `${EntityName}/fetch-many-active`,
  (
    payload: RouteComponentProps['location']['search'],
    deferred: DeferredAction['meta']['deferred'],
    persist?: boolean,
  ) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)

export const fetchDeletedSources = createAction(
  `${EntityName}/fetch-many-deleted`,
  (
    payload: RouteComponentProps['location']['search'],
    deferred: DeferredAction['meta']['deferred'],
    persist?: boolean,
  ) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)

export const fetchSource = createAction(
  `${EntityName}/fetch-single`,
  (payload: UUID, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const fetchSourceTotals = createAction(
  `${EntityName}/fetch-totals`,
  (payload: RouteComponentProps['location']['search'], deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)
