/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import { EventLog } from '@/codecs/event-log.codecs'
import { UUID } from '@/utilities/codec'

import { EntityName } from './event-log.constants'

// ---------------------------------------------------------------------------

export function normalizeEventLogs(records: EventLog[]) {
  const EventLog = new schema.Entity(EntityName)
  const EventLogs = [EventLog]

  type NormalizedEventLogs = {
    result: UUID[]
    entities: {
      [EntityName]: Record<UUID, EventLog>
    }
  }

  const eventLogs: NormalizedEventLogs = normalize(records, EventLogs)

  return eventLogs
}
