/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil, isNotNilOrEmpty } from '@randori/rootkit'

import * as Codecs from '@/codecs'
import { GateSelectors, OrganizationSelectors } from '@/store'
import * as CrudQueryUtils from '@/utilities/crud-query'
import * as EntityUtils from '@/utilities/r-entity'
import { ExhaustiveError, RandoriEntityTypeError } from '@/utilities/r-error'

import { FIELD_MAP, FieldMap } from './field-map'
import { getInternalListByEntity, getInternalListByEntityAndFlags } from './internal-list'

// NVD related filters - these don't come from OpenAPI
export const NVD_FILTER_OBJECTS: Record<string, Codecs.SchemaProperty> = {
  cpe_str: { type: 'string' } as Codecs.SchemaProperty,
  cve: { type: 'string' } as Codecs.SchemaProperty,
  cvss: { type: 'number' } as Codecs.SchemaProperty,
}

export const NVD_FILTER_KEYS = new Set([...Object.keys(NVD_FILTER_OBJECTS)])

// ---------------------------------------------------------------------------

function getTypeByFormat(type: Codecs.OASType, format?: Codecs.OASFormat) {
  switch (type) {
    case 'string': {
      if (format === 'date-time') {
        return 'datetime'
      } else {
        return type
      }
    }

    case 'integer':
    case 'number': {
      if (format === 'float') {
        // @TODO: float filter option
        return 'integer'
      } else {
        return 'integer'
      }
    }

    default: {
      return type
    }
  }
}

// this should account for formats and other OAS spec details to build these
// formats in the schema map to extra types in our rules, ie date, uuid, etc
function buildGenericRule(uiId: string, type: Codecs.OASType, format?: Codecs.OASFormat): CrudQueryUtils.CrudRule {
  return {
    field: `table.${uiId}`,
    id: `table.${uiId}`,
    input: 'text',
    type: getTypeByFormat(type, format),
    ui_id: uiId,
  }
}

function buildGenericJsonApiRule(
  uiId: string,
  type: Codecs.OASType,
  format?: Codecs.OASFormat,
): CrudQueryUtils.CrudRule {
  return {
    field: `attributes.${uiId}`,
    id: `attributes.${uiId}`,
    input: 'text',
    type: getTypeByFormat(type, format),
    ui_id: uiId,
  }
}

type GetInitRulesConfig = {
  dataType: EntityUtils.EntityType
  features: ReturnType<typeof OrganizationSelectors.selectFeatures>
  columns: Record<string, Codecs.SchemaProperty>
  useInternal: boolean
  staticFlags: ReturnType<typeof GateSelectors.staticFlags>
  noExtraProcessing: boolean
}

export function getInitRules({
  columns,
  dataType,
  features,
  noExtraProcessing,
  staticFlags,
  useInternal,
}: GetInitRulesConfig): CrudQueryUtils.Rule[] {
  // Remove CPE while it's still materialized, we can remove this forced deletion later
  const { cpe: _cpe /* shake */, ...keepColumns } = columns

  columns = keepColumns

  const columnList = Object.keys(columns)

  if (noExtraProcessing === true) {
    const _filters = columnList.map((col) => {
      if (isNotNil(FIELD_MAP[col])) {
        return FIELD_MAP[col]
      } else {
        const _col = columns[col]
        return buildGenericRule(col, _col.type, _col.format)
      }
    }) as CrudQueryUtils.Rules

    return _filters
  }

  // these are advanced cases composed of several fields, range type, or other cases.
  const overrideMap: FieldMap = {
    ['artifact_types']: FIELD_MAP.artifact_type,
    ['affiliation_state']: FIELD_MAP.unaffiliated,
    ['longitude']: FIELD_MAP.location,
  }

  const overrideList = Object.keys(overrideMap)

  const featureFiltersOff = (filter: CrudQueryUtils.Rule) => {
    if (!isNotNil(filter.ui_id)) {
      return false
    }

    const hasAuthorization = (f: typeof features) => {
      if (isNotNilOrEmpty(f.VULNERABILITY_VALIDATION)) {
        return false
      } else if (isNotNilOrEmpty(f.ATTACK_AUTHORIZATION)) {
        return false
      }

      return true
    }

    const lookup: Record<string, boolean> = {
      attack_authorization: hasAuthorization(features),
      authorizing_policies: false,
      characteristic_tags: !isNotNilOrEmpty(features.AUTO_TAG),
      detection_authorization: false,
      impact_score: false,
      priority_score: false,
      recon_status: !isNotNilOrEmpty(features.STATUS), // is this used - not in any codec?
      tech_category: false,
      unaffiliated: !isNotNilOrEmpty(features.UNAFFILIATION),
    }

    return !lookup[filter.ui_id]
  }

  switch (dataType) {
    case 'activity_instance': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      const mapped = _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })

      return mapped
    }

    case 'activity_configuration': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          // to prevent collisions with other 'name' fields
          if (col === 'name' && isNotNil(FIELD_MAP['activity_configuration_name'])) {
            return FIELD_MAP['activity_configuration_name']
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericJsonApiRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'artifact': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'exception_policy': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          // to prevent collisions with overlapping fields since this is a JSON API
          if (col === 'name' && isNotNil(FIELD_MAP['exception_policy_name'])) {
            return FIELD_MAP['exception_policy_name']
          } else if (col === 'description' && isNotNil(FIELD_MAP['exception_policy_description'])) {
            return FIELD_MAP['exception_policy_description']
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericJsonApiRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'policy': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'savedViews': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'service': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
      // .concat(addList)
    }

    case 'social': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'hostname': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'ip': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'network': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'target': {
      const targetOverrideMap: FieldMap = {
        ['name']: FIELD_MAP.serviceName,
        ['target_num_authorized_detections']: FIELD_MAP.authorized_detections,
      }

      const addList = [FIELD_MAP.target_artifact_type]
      const internalList = getInternalListByEntityAndFlags(dataType, staticFlags)
      const targetOverrideList = Object.keys(targetOverrideMap)

      const _showInternal = showInternal(useInternal, internalList)

      const _toRule = toRule(
        [...overrideList, ...targetOverrideList],
        {
          ...overrideMap,
          ...targetOverrideMap,
        },
        columns,
      )

      // @TODO: Consolidate these transforms
      //
      // Doing a filter/map/filter/concat/map is a good clue that this is just
      // stuff bolted to stuff, and can be made coherent again by considering
      // what is going on in this applicative pipeline.
      const filters = columnList
        .filter(_showInternal)
        .map(_toRule)
        .filter(featureFiltersOff)
        .concat(addList)
        .map((filter) => {
          return {
            ...filter,
            randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
          }
        })

      return filters
    }

    case 'detection_target':
    case 'topLevelDetection': {
      const targetOverrideMap: FieldMap = {
        ['detection_authorization_state']: FIELD_MAP.detection_authorization,
        ['name']: FIELD_MAP.serviceName,
      }

      const addList = [FIELD_MAP.target_artifact_type]
      const internalList = getInternalListByEntityAndFlags(dataType, staticFlags)
      const targetOverrideList = Object.keys(targetOverrideMap)

      const _showInternal = showInternal(useInternal, internalList)

      const _toRule = toRule(
        [...overrideList, ...targetOverrideList],
        {
          ...overrideMap,
          ...targetOverrideMap,
        },
        columns,
      )

      const filters = columnList
        .filter(_showInternal)
        .map(_toRule)
        .filter(featureFiltersOff)
        .concat(addList)
        .map((filter) => {
          return {
            ...filter,
            randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
          }
        })

      return filters
    }

    case 'implant': {
      const implantOverrideMap: FieldMap = {
        ['status']: FIELD_MAP.implant_status,
      }
      const implantOverrideList = Object.keys(implantOverrideMap)
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (implantOverrideList.includes(col)) {
            return implantOverrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'redirector': {
      const redirectorOverrideMap: FieldMap = {
        ['status']: FIELD_MAP.redirector_status,
      }
      const redirectorOverrideList = Object.keys(redirectorOverrideMap)
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (redirectorOverrideList.includes(col)) {
            return redirectorOverrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'runbook': {
      const runbookOverrideMap: FieldMap = {
        ['dst_email']: FIELD_MAP.dst_email_many,
        ['dst_host']: FIELD_MAP.dst_host_many,
        ['dst_ip']: FIELD_MAP.dst_ip_many,
        ['dst_mac']: FIELD_MAP.dst_mac_many,
        ['dst_misc']: FIELD_MAP.dst_misc_many,
        ['dst_network']: FIELD_MAP.dst_network_many,
        ['dst_path']: FIELD_MAP.dst_path_many,
        ['dst_port']: FIELD_MAP.dst_port_many,
        ['src_email']: FIELD_MAP.src_email_many,
        ['src_host']: FIELD_MAP.src_host_many,
        ['src_ip']: FIELD_MAP.src_ip_many,
        ['src_mac']: FIELD_MAP.src_mac_many,
        ['src_misc']: FIELD_MAP.src_misc_many,
        ['status']: FIELD_MAP.runbook_status,
        ['name']: FIELD_MAP.runbook_name,
      }
      const runbookOverrideList = Object.keys(runbookOverrideMap)

      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (runbookOverrideList.includes(col)) {
            return runbookOverrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'blueprint': {
      const blueprintOverrideMap: FieldMap = {
        ['bart_id']: FIELD_MAP.blueprint_approval_status,
      }
      const blueprintOverrideList = Object.keys(blueprintOverrideMap)

      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (blueprintOverrideList.includes(col)) {
          return blueprintOverrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'bar': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'globalIps': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'globalHostname': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'globalNetwork': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'term': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'poc': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'globalService': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'report': {
      const reportOverrideMap: FieldMap = {
        ['type']: FIELD_MAP.report_type,
      }

      const reportOverrideList = Object.keys(reportOverrideMap)

      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (reportOverrideList.includes(col)) {
            return reportOverrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'serviceSuggestion': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'serviceRule': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'characteristicRule': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'globalArtifact': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'organization': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'peer': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'activity_configuration': {
      const _filters = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff)
    }

    case 'authorization_policy': {
      const internalList = getInternalListByEntity(dataType)

      const _filters = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (col === 'name') {
            const _col = columns[col]
            return {
              field: `table.name`,
              id: `table.name`,
              input: 'text',
              type: getTypeByFormat(_col.type, _col.format),
              ui_id: 'policy_name',
            }
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]

            return buildGenericRule(col, _col.type, _col.format)
          }
        }) as CrudQueryUtils.Rules

      return _filters.filter(featureFiltersOff).map((filter) => {
        return {
          ...filter,
          randoriOnly: isNotNil(filter.ui_id) ? internalList.includes(filter.ui_id) : false,
        }
      })
    }

    case 'bdo_detection': {
      const internalList = getInternalListByEntity(dataType)

      const _filters: CrudQueryUtils.Rules = columnList
        .filter((c) => useInternal || !internalList.includes(c))
        .map((col) => {
          if (overrideList.includes(col)) {
            return overrideMap[col]
          }

          if (isNotNil(FIELD_MAP[col])) {
            return FIELD_MAP[col]
          } else {
            const _col = columns[col]
            return buildGenericRule(col, _col.type, _col.format)
          }
        })

      const filters = _filters.filter(featureFiltersOff)

      return filters
    }

    case 'exception_policy': {
      const _filters: CrudQueryUtils.Rules = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      })

      return _filters.filter(featureFiltersOff)
    }

    case 'asset': {
      const _filters: CrudQueryUtils.Rules = columnList.map((col) => {
        if (overrideList.includes(col)) {
          return overrideMap[col]
        }

        if (isNotNil(FIELD_MAP[col])) {
          return FIELD_MAP[col]
        } else {
          const _col = columns[col]
          return buildGenericRule(col, _col.type, _col.format)
        }
      })

      return _filters.filter(featureFiltersOff)
    }

    // No filtering support so should be empty but revisit if anything changes
    case 'perspective': {
      return []
    }

    case 'source': {
      return []
    }

    case 'action':
    case 'applicable_activity':
    case 'entity_for_activity_instance':
    case 'event_log':
    case 'hostnameForIp':
    case 'integrations':
    case 'ipForHostname':
    case 'ipForNetwork':
      throw new RandoriEntityTypeError({ entityType: dataType })

    default:
      throw new ExhaustiveError(dataType)
  }
}

const toRule = (overrideList: string[], overrideMap: FieldMap, columns: Record<string, Codecs.SchemaProperty>) => {
  return (c: string): CrudQueryUtils.Rule => {
    if (overrideList.includes(c)) {
      return overrideMap[c]
    }

    if (isNotNil(FIELD_MAP[c])) {
      return FIELD_MAP[c]
    } else {
      const column = columns[c]
      return buildGenericRule(c, column.type, column.format)
    }
  }
}

const showInternal = (useInternal: boolean, internalList: string[]) => (c: string) =>
  useInternal || !internalList.includes(c)
