/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { CrudRuleGroup, isNotNil, isNotNilOrEmpty } from '@randori/rootkit'
import { noop, omit } from 'lodash/fp'
import qs from 'query-string'
import { head, isEmpty, last } from 'ramda'
import { AnyAction } from 'redux'
import { all, call, put, select } from 'typed-redux-saga/macro'

import * as Codecs from '@/codecs'
import * as EntityDetailUtils from '@/pages/entity-detail/entity-detail.utils'
import * as Store from '@/store'
import * as _PolicyActions from '@/store/actions/recon/policy.actions'
import * as _ReconActions from '@/store/actions/recon/recon.actions'
import { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { createPriorityFilter } from '@/utilities/priority'
import * as QueryFilterUtils from '@/utilities/query-filter-utils'
import * as EntityUtils from '@/utilities/r-entity'
import { ExhaustiveError, RandoriEntityTypeError } from '@/utilities/r-error'
import * as Stats from '@/utilities/stats'

import * as ReconSagaUtils from './recon.sagas.utils'

// ---------------------------------------------------------------------------

export function* _CHARACTERISTICS_BY_PRIORITY_FETCH(
  io: MiddlewaresIO,
  action: _ReconActions.CHARACTERISTICS_BY_PRIORITY_FETCH,
) {
  const priorityRanges = yield* select(Store.PreferencesSelectors.selectPriorityPreferences)

  const priorityValues = {
    low: false,
    high: false,
    medium: false,
  }

  const lowPriorityFilter = createPriorityFilter({ ...priorityValues, low: true }, priorityRanges)
  const mediumPriorityFilter = createPriorityFilter({ ...priorityValues, medium: true }, priorityRanges)
  const highPriorityFilter = createPriorityFilter({ ...priorityValues, high: true }, priorityRanges)

  const priorityFilters = [lowPriorityFilter, mediumPriorityFilter, highPriorityFilter]

  const createTagFilter = (tag: string) => ({
    field: 'table.characteristic_tags',
    id: 'table.characteristic_tags',
    input: 'text',
    operator: 'contains_element',
    type: 'array',
    ui_id: 'characteristic_tags',
    value: tag,
  })

  const createSerializedRule = (tagFilter: Record<string, string>, priorityFilter: CrudQueryUtils.CrudRuleGroup) =>
    CrudQueryUtils.createQuery({
      limit: 0,
      offset: 0,
      q: CrudQueryUtils.serializeQ({
        condition: 'AND' as const,
        rules: [tagFilter, priorityFilter, ...CrudQueryUtils.confidenceAndAffiliationFilterRules],
      }),
    })

  const queriesByTag = action.payload.tags.reduce((queries, tag) => {
    const tagFilter = createTagFilter(tag.content)

    return {
      ...queries,
      [tag.content]: priorityFilters.map((priorityFilter) => createSerializedRule(tagFilter, priorityFilter)),
    }
  }, {})

  type Response = Codecs.HostnamesResponse | Codecs.IpsResponse | Codecs.DetectionTargetResponse

  function* makeCalls(getEntityApi: (q: string) => Promise<Response>, queries: Record<string, string[]>) {
    const tags: Record<string, number[]> = {}

    for (const tag in queries) {
      tags[tag] = []

      for (const query of queries[tag]) {
        const result = yield* call(getEntityApi, query)

        tags[tag].push(result.total)
      }
    }

    return tags
  }

  switch (action.payload.entityType) {
    case 'hostname': {
      const hostnameTags = yield* makeCalls(io.api.recon.getHostnames, queriesByTag)
      return hostnameTags
    }

    case 'ip': {
      const ipTags = yield* makeCalls(io.api.recon.getIps, queriesByTag)
      return ipTags
    }

    case 'target': {
      const targetTags = yield* makeCalls(io.api.recon.getTargets, queriesByTag)
      return targetTags
    }

    case 'action':
    case 'activity_configuration':
    case 'activity_instance':
    case 'applicable_activity':
    case 'artifact':
    case 'asset':
    case 'bar':
    case 'blueprint':
    case 'characteristicRule':
    case 'detection_target':
    case 'entity_for_activity_instance':
    case 'event_log':
    case 'exception_policy':
    case 'globalArtifact':
    case 'globalHostname':
    case 'globalIps':
    case 'globalNetwork':
    case 'globalService':
    case 'hostnameForIp':
    case 'implant':
    case 'integrations':
    case 'ipForHostname':
    case 'ipForNetwork':
    case 'network':
    case 'organization':
    case 'peer':
    case 'perspective':
    case 'poc':
    case 'policy':
    case 'redirector':
    case 'report':
    case 'runbook':
    case 'savedViews':
    case 'service':
    case 'serviceRule':
    case 'serviceSuggestion':
    case 'social':
    case 'source':
    case 'term':
    case 'topLevelDetection':
    case 'authorization_policy':
    case 'bdo_detection':
      throw new RandoriEntityTypeError({ entityType: action.payload.entityType })

    default:
      throw new ExhaustiveError(action.payload.entityType)
  }
}

export function* _ENTITY_TOTAL_COUNT_FETCH(io: MiddlewaresIO, action: _ReconActions.ENTITY_TOTAL_COUNT_FETCH) {
  const query = `?${action.payload.query}&reversed_nulls=true`

  switch (action.payload.entityType) {
    case 'activity_instance': {
      const activityInstances = yield* call(io.api.activities.getActivityInstances, query)
      return activityInstances.total
    }

    case 'activity_configuration': {
      const activityConfigurations = yield* call(io.api.activities.getActivityConfigurations, query)
      return activityConfigurations.meta.total
    }

    case 'asset': {
      const assets = yield* call(io.api.asset.getAssets, get(query, QueryString))
      return assets.total
    }

    case 'exception_policy': {
      const exceptionPolicies = yield* call(io.api.exceptionPolicy.getExceptionPolicies, get(query, QueryString))
      return exceptionPolicies.meta.total
    }

    case 'hostname': {
      const hostnames = yield* call(io.api.hostname.getHostnames, get(query, QueryString))
      return hostnames.total
    }

    case 'implant': {
      const implants = yield* call(io.api.attack.getImplants, query)
      return implants.total
    }

    case 'ip': {
      const ips = yield* call(io.api.ip.getIps, get(query, QueryString))
      return ips.total
    }

    case 'network': {
      const networks = yield* call(io.api.network.getNetworks, get(query, QueryString))
      return networks.total
    }

    case 'redirector': {
      const redirectors = yield* call(io.api.attack.getRedirectors, query)
      return redirectors.total
    }

    case 'runbook': {
      const runbooks = yield* call(io.api.attack.getRunbooks, query)
      return runbooks.total
    }

    case 'service': {
      const services = yield* call(io.api.service.getServices, get(query, QueryString))
      return services.total
    }

    case 'social': {
      const socialEntities = yield* call(io.api.recon.getSocialEntities, query)
      return socialEntities.total
    }

    case 'target':
      const targets = yield* call(io.api.target.getTargets, get(query, QueryString))
      return targets.total

    case 'bdo_detection': {
      const detections = yield* call(io.api.detection.getDetections, get(query, QueryString))
      return detections.total
    }

    case 'source': {
      const sources = yield* call(io.api.perspective.getActiveSources, get(query, QueryString))
      return sources.total
    }

    case 'action':
    case 'activity_configuration':
    case 'applicable_activity':
    case 'artifact':
    case 'authorization_policy':
    case 'bar':
    case 'blueprint':
    case 'characteristicRule':
    case 'detection_target':
    case 'entity_for_activity_instance':
    case 'event_log':
    case 'globalArtifact':
    case 'globalHostname':
    case 'globalIps':
    case 'globalNetwork':
    case 'globalService':
    case 'hostnameForIp':
    case 'integrations':
    case 'ipForHostname':
    case 'ipForNetwork':
    case 'organization':
    case 'peer':
    case 'perspective':
    case 'poc':
    case 'policy':
    case 'report':
    case 'savedViews':
    case 'serviceRule':
    case 'serviceSuggestion':
    case 'term':
    case 'topLevelDetection':
      throw new RandoriEntityTypeError({ entityType: action.payload.entityType })

    default:
      throw new ExhaustiveError(action.payload.entityType)
  }
}

export function* _ENTITY_GROUPING_FETCH(io: MiddlewaresIO, action: AnyAction) {
  if (action.payload.type === 'target') {
    const data = yield* call(io.api.recon.getTargetTemptationGroups, action.payload.body)

    return data
  } else if (action.payload.type === 'impact_score') {
    const data = yield* call(io.api.recon.getImpactScoreGroups, action.payload.body)

    return data
  } else if (action.payload.type === 'status') {
    const data = yield* call(io.api.recon.getStatusGroups, action.payload.body)

    return data
  } else if (action.payload.type === 'priority_score') {
    const data = yield* call(io.api.recon.getPriorityGroups, action.payload.body)

    return data
  } else {
    throw new Error(`Unsupported groupingType: ${action.payload.type}`)
  }
}

/**
 * Given a `q` and a grouping type to retrieve grouping counts
 *
 * @remarks
 * The grouping endpoints do not support rFlask query objects
 *
 * @see
 * GroupingType
 *
 * param io - MiddlewaresIO
 * param action - FETCH_QUERY_AS_GROUPING
 *
 * @returns `{ counts: Count[]; total: number; }`
 */
export function* _FETCH_QUERY_AS_GROUPING(io: MiddlewaresIO, action: _ReconActions.FETCH_QUERY_AS_GROUPING) {
  const queryFn = ReconSagaUtils.getQueryFnByEntityType(action.payload.entityType, io.api)

  type QueryReturn = ReturnType<typeof queryFn>
  type QueryReturnUnwrapped = ThenArg<QueryReturn>
  type ThenArg<T> = T extends PromiseLike<infer U> ? U : T

  // deconstruct q from action, add filter
  const activeFilters = CrudQueryUtils.unserializeQ(action.payload.q === undefined ? '' : action.payload.q)

  const filterCalls = Object.keys(action.payload.filters).reduce((acc: Record<string, QueryReturnUnwrapped>, curr) => {
    const root = QueryFilterUtils.insertAndableGroup(activeFilters, action.payload.filters[curr])

    const combinedFilter = qs.stringify({
      limit: 0,
      q: CrudQueryUtils.serializeQ(root),
    })

    // @ts-expect-error cannot type this
    const _res: SagaUtils.CallReturnType<typeof queryFn> = call(queryFn, `?${combinedFilter}`)

    acc[curr] = _res

    return acc
  }, {})

  // @ts-expect-error cannot type `all`
  const results = yield all(filterCalls)

  type Count = {
    count: number
    type: string
  }

  const final = Object.keys(results).reduce(
    (acc: { counts: Count[]; total: number }, curr: string) => {
      const currentResult = results[curr]

      const count: Count = {
        count: currentResult.total,
        type: curr,
      }

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return { counts: [...acc.counts, count], total: acc.total + currentResult.total }
    },
    { counts: [], total: 0 },
  )

  return final
}

export function* _RECON_ENTITY_PATCH(io: MiddlewaresIO, action: _ReconActions.RECON_ENTITY_PATCH) {
  const { type, body } = action.payload

  const { count } = yield* call(io.api.recon.patchEntity, body, type)

  if (count > 0) {
    // && < pageSize ??

    const serializedQuery = CrudQueryUtils.createQuery({ q: CrudQueryUtils.serializeQ(body.q) })

    if (!action.payload.hydrateDataFromQ) {
      return
    }

    switch (type) {
      case EntityUtils.getEndpointByType('target'): {
        const query = {
          ...body.q,
          rules: body.q.rules.map((rule: any) => {
            rule.id = 'table.target_id'
            rule.field = 'table.target_id'
            return rule
          }),
        }

        const serializedQ = CrudQueryUtils.createQuery({ q: CrudQueryUtils.serializeQ(query) })

        const { data: targetData } = yield* call(io.api.recon.getTargets, serializedQ)

        return targetData
      }

      case EntityUtils.getEndpointByType('service'): {
        const { data: serviceData } = yield* call(io.api.recon.getServices, serializedQuery)

        return serviceData
      }

      case EntityUtils.getEndpointByType('social'): {
        const { data: socialData } = yield* call(io.api.recon.getSocialEntities, serializedQuery)

        return socialData
      }

      case EntityUtils.getEndpointByType('network'): {
        const { data: networkData } = yield* call(io.api.recon.getNetworks, serializedQuery)

        return networkData
      }

      case EntityUtils.getEndpointByType('ip'): {
        const { data: ipData } = yield* call(io.api.recon.getIps, serializedQuery)

        return ipData
      }

      case EntityUtils.getEndpointByType('hostname'): {
        const { data: hostnameData } = yield* call(io.api.recon.getHostnames, serializedQuery)

        return hostnameData
      }

      default:
        io.stdout('_patchEntity - type not found')
    }
  }
}

export function* _RECON_DATA_FOR_ENTITY_FETCH(io: MiddlewaresIO, action: _ReconActions.FetchDataForEntity) {
  const { additionalQueryRules, dataType, entityType, id, idField, limit, offset, sort } = action.payload

  if (!dataType) {
    throw new Error('dataType is null')
  }

  const _rules: Array<CrudQueryUtils.CrudRuleGroup | CrudQueryUtils.CrudRule> = [
    {
      ui_id: idField,
      id: `table.${idField}`,
      field: `table.${idField}`,
      type: 'string',
      input: 'text',
      operator: 'equal',
      value: id,
    },
  ]

  const q = {
    condition: 'AND',
    rules: _rules.concat(additionalQueryRules ? additionalQueryRules : []),
  }

  const serializedQuery = limit
    ? `?${qs.stringify({ limit, q: CrudQueryUtils.serializeQ(q), sort, offset })}`
    : `?${qs.stringify({ q: CrudQueryUtils.serializeQ(q), sort, offset })}`

  switch (entityType) {
    // since this is asking for data per target, but we interact with detections
    // we need to then go get targets for those detections

    case 'ip': {
      const res = yield* call(io.api.recon.getDataForIp, dataType, serializedQuery)

      return res
    }

    case 'service': {
      const res = yield* call(io.api.recon.getDataForService, dataType, serializedQuery)

      return res
    }

    case 'network': {
      const res = yield* call(io.api.recon.getDataForNetwork, dataType, serializedQuery)

      return res
    }

    case 'hostname': {
      const res = yield* call(io.api.recon.getIpsForHostname, serializedQuery)

      return res
    }

    default:
      throw new RandoriEntityTypeError({ entityType: entityType })
  }
}

export function* _RECON_TARGETS_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const result = yield* call(io.api.recon.getTargets, CrudQueryUtils.createQuery(action.payload))

  yield* put(Store.ReconActions.RECON_TARGETS_UPDATE(result))

  return result
}

export function* _RECON_TARGETS_TOTAL_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const _current = yield* call(io.api.recon.getTargets, CrudQueryUtils.createQuery(action.payload))
  const _total = yield* call(io.api.recon.getTargets, CrudQueryUtils.createUnfilteredQuery())
  const _unaffiliated = yield* call(io.api.recon.getTargets, CrudQueryUtils.createUnaffiliatedQuery())

  const totals = {
    unfilteredTotal: _total.total,
    unaffiliatedTotal: _unaffiliated.total,
  }

  yield* put(Store.ReconActions.TARGET_TOTALS_STORE_UPDATE(totals))

  return {
    current: _current.total,
    total: _total.total,
    unaffiliatedTotal: _unaffiliated.total,
  }
}

export function* _RECON_HOSTNAMES_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const filteredResponse = yield* call(io.api.recon.getHostnames, CrudQueryUtils.createQuery(action.payload))

  const unfilteredResponse = yield* call(io.api.recon.getHostnames, CrudQueryUtils.createUnfilteredQuery())

  const unaffiliatedResponse = yield* call(io.api.recon.getHostnames, CrudQueryUtils.createUnaffiliatedQuery())

  const totals = {
    unfilteredTotal: unfilteredResponse.total,
    unaffiliatedTotal: unaffiliatedResponse.total,
  }

  yield* put(Store.ReconActions.HOSTNAME_TOTALS_STORE_UPDATE(totals))

  return {
    ...filteredResponse,
    unfilteredTotal: unfilteredResponse.total,
    unaffiliatedTotal: unaffiliatedResponse.total,
  }
}

export function* _RECON_HOSTNAME_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const { data } = yield* call(io.api.recon.getHostname, action.payload)

  return data
}

export function* _RECON_DATA_FOR_HOSTNAME_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const q = {
    condition: 'AND',
    rules: [
      {
        ui_id: 'hostname_id',
        id: 'table.hostname_id',
        field: 'table.hostname_id',
        type: 'string',
        input: 'text',
        operator: 'equal',
        value: action.payload.id,
      },
    ],
  }

  const query = CrudQueryUtils.createQuery({ ...action.payload.options, q: CrudQueryUtils.serializeQ(q) })

  const response = yield* call(io.api.recon.getIpsForHostname, query)

  return response
}

export function* _RECON_TT_FOR_HOSTNAME_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const q1 = {
    condition: 'AND',
    rules: [
      {
        ui_id: 'hostname_id',
        id: 'table.hostname_id',
        field: 'table.hostname_id',
        type: 'string',
        input: 'text',
        operator: 'equal',
        value: action.payload,
      },
    ],
  }

  const ips = yield* call(
    io.api.recon.getIpsForHostname,
    CrudQueryUtils.createQuery({ q: CrudQueryUtils.serializeQ(q1) }),
  )

  const mostTemptingIP = last(ReconSagaUtils.sortByTT(ips.data))

  if (!mostTemptingIP) {
    return
  }

  const q2 = {
    condition: 'AND',
    rules: [
      {
        ui_id: 'ip_id',
        id: 'table.ip_id',
        field: 'table.ip_id',
        type: 'string',
        input: 'text',
        operator: 'equal',
        value: mostTemptingIP.ip_id,
      },
    ],
  }

  const targets = yield* call(
    io.api.recon.getTargets,
    `?${qs.stringify({ limit: 1, q: CrudQueryUtils.serializeQ(q2), sort: ['-target_temptation'] })}`,
  )

  return targets
}

export function* _RECON_IPS_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const filteredResponse = yield* call(io.api.recon.getIps, CrudQueryUtils.createQuery(action.payload))

  const unfilteredResponse = yield* call(io.api.recon.getIps, CrudQueryUtils.createUnfilteredQuery())

  const unaffiliatedResponse = yield* call(io.api.recon.getIps, CrudQueryUtils.createUnaffiliatedQuery())

  const totals = {
    unfilteredTotal: unfilteredResponse.total,
    unaffiliatedTotal: unaffiliatedResponse.total,
  }

  yield* put(Store.ReconActions.IP_TOTALS_STORE_UPDATE(totals))

  return {
    ...filteredResponse,
    unfilteredTotal: unfilteredResponse.total,
    unaffiliatedTotal: unaffiliatedResponse.total,
  }
}

export function* _IP_FETCH(io: MiddlewaresIO, action: _ReconActions.IP_FETCH) {
  const results = yield* call(io.api.recon.getIp, action.payload)

  yield* put(Store.ReconActions.IP_STORE_UPDATE(results.data))

  const guidanceContent = EntityDetailUtils.getGuidanceContent(results.data)

  yield* put(
    Store.UIActions.SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE({
      entityType: 'ip',
      guidanceContent,
    }),
  )

  return results
}

export function* _HOSTNAME_FETCH(io: MiddlewaresIO, action: _ReconActions.HOSTNAME_FETCH) {
  const results = yield* call(io.api.recon.getHostname, action.payload)

  yield* put(Store.ReconActions.HOSTNAME_STORE_UPDATE(results.data))

  const guidanceContent = EntityDetailUtils.getGuidanceContent(results.data)

  yield* put(
    Store.UIActions.SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE({
      entityType: 'hostname',
      guidanceContent,
    }),
  )

  return results
}

export function* _NETWORK_FETCH(io: MiddlewaresIO, action: _ReconActions.NETWORK_FETCH) {
  const results = yield* call(io.api.recon.getNetwork, action.payload)

  yield* put(Store.ReconActions.NETWORK_STORE_UPDATE(results.data))

  const guidanceContent = EntityDetailUtils.getGuidanceContent(results.data)

  yield* put(
    Store.UIActions.SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE({
      entityType: 'network',
      guidanceContent,
    }),
  )

  return results
}

export function* _RECON_NETWORKS_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const filteredResponse = yield* call(io.api.recon.getNetworks, CrudQueryUtils.createQuery(action.payload))

  const unfilteredResponse = yield* call(io.api.recon.getNetworks, CrudQueryUtils.createUnfilteredQuery())

  const unaffiliatedResponse = yield* call(io.api.recon.getNetworks, CrudQueryUtils.createUnaffiliatedQuery())

  const totals = {
    unfilteredTotal: unfilteredResponse.total,
    unaffiliatedTotal: unaffiliatedResponse.total,
  }

  yield* put(Store.ReconActions.NETWORK_TOTALS_STORE_UPDATE(totals))

  return {
    ...filteredResponse,
    unfilteredTotal: unfilteredResponse.total,
    unaffiliatedTotal: unaffiliatedResponse.total,
  }
}

export function* _RECON_NETWORK_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const results = yield* call(io.api.recon.getNetwork, action.payload)

  return results
}

export function* _RECON_SAVED_VIEWS_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const results = yield* call(io.api.recon.getSavedViews, CrudQueryUtils.createQuery(action.payload))

  return results
}

export function* _RECON_SERVICES_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const filteredResponse = yield* call(io.api.recon.getServices, CrudQueryUtils.createQuery(action.payload))

  const unfilteredResponse = yield* call(io.api.recon.getServices, CrudQueryUtils.createUnfilteredQuery())

  const totals = {
    unfilteredTotal: unfilteredResponse.total,
    unaffiliatedTotal: 0,
  }

  yield* put(Store.ReconActions.SERVICE_TOTALS_STORE_UPDATE(totals))

  return {
    ...filteredResponse,
    unfilteredTotal: unfilteredResponse.total,
    unaffiliatedTotal: 0,
  }
}

export function* _RECON_SERVICE_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const results = yield* call(io.api.recon.getService, action.payload)

  yield* put(Store.ReconActions.SERVICE_STORE_UPDATE(results.data))

  const guidanceContent = EntityDetailUtils.getGuidanceContent(results.data)

  yield* put(
    Store.UIActions.SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE({
      entityType: 'service',
      guidanceContent,
    }),
  )

  return results
}

// @TODO: dead code
export function* _RECON_TT_FETCH(io: MiddlewaresIO, _action: AnyAction) {
  const q = {
    condition: 'OR',
    rules: [
      {
        condition: 'AND',
        rules: [
          {
            condition: 'OR',
            rules: [
              {
                field: 'table.name',
                operator: 'equal',
                value: 'top_targets',
              },
              {
                field: 'table.name',
                operator: 'equal',
                value: 'top_services',
              },
              {
                field: 'table.name',
                operator: 'equal',
                value: 'top_ips',
              },
            ],
          },
        ],
      },
      {
        condition: 'AND',
        rules: [
          {
            field: 'table.time',
            operator: 'equal',
            value: Stats.getDateAgo(600000),
          },
          {
            field: 'table.name',
            operator: 'equal',
            value: 'top_targets',
          },
        ],
      },
    ],
  }

  const { data: records } = yield* call(io.api.recon.getStatistics, qs.stringify({ q: CrudQueryUtils.serializeQ(q) }))

  // const tt = Stats.getCurrentTopTarget(records as Codecs.RequiredStats[])
  const ips = Stats.getCurrentIps(records as Codecs.RequiredStats[])
  const services = Stats.getCurrentServices(records as Codecs.RequiredStats[])
  const delta = Stats.getTopTargetsDelta(records as Codecs.RequiredStats[])

  return {
    delta,
    ips,
    services,
    // tt,
  }
}

export function* _RECON_STATS_PRIO_FETCH(io: MiddlewaresIO, _action: AnyAction) {
  const createStatFilters = (value: string) => ({
    condition: 'AND',
    rules: [
      {
        field: 'table.name',
        operator: 'equal',
        value: value,
      },
      {
        field: 'table.time',
        operator: 'is_not_null',
        value: true,
      },
    ],
  })

  const hostnamesFilter = createStatFilters('top_prio_hostnames')
  const ipsFilter = createStatFilters('top_prio_ips')
  const networksFilter = createStatFilters('top_prio_networks')
  const targetsFilter = createStatFilters('top_prio_targets')

  const { data: _hostnames } = yield* call(
    io.api.recon.getStatistics,
    qs.stringify({ q: CrudQueryUtils.serializeQ(hostnamesFilter), sort: '-time' }),
  )

  const { data: _ips } = yield* call(
    io.api.recon.getStatistics,
    qs.stringify({ q: CrudQueryUtils.serializeQ(ipsFilter), sort: '-time' }),
  )

  const { data: _networks } = yield* call(
    io.api.recon.getStatistics,
    qs.stringify({ q: CrudQueryUtils.serializeQ(networksFilter), sort: '-time' }),
  )

  const { data: _targets } = yield* call(
    io.api.recon.getStatistics,
    qs.stringify({ q: CrudQueryUtils.serializeQ(targetsFilter), sort: '-time' }),
  )

  const hostnames = Stats.getHighPriorityHostsStats(_hostnames)
  const ips = Stats.getHighPriorityIpsStats(_ips)
  const networks = Stats.getHighPriorityNetworksStats(_networks)
  const targets = Stats.getHighPriorityTargetStats(_targets)

  return {
    targets,
    ips,
    hostnames,
    networks,
  }
}

export function* _RECON_TARGET_COUNT_FOR_ENTITY_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const _rules: Array<CrudQueryUtils.CrudRuleGroup | CrudQueryUtils.CrudRule> = [
    {
      ui_id: `${action.payload.idField}`,
      id: `table.${action.payload.idField}`,
      field: `table.${action.payload.idField}`,
      type: 'string',
      input: 'text',
      operator: 'equal',
      value: action.payload.id,
    },
  ]

  const q = {
    condition: 'AND',
    rules: _rules,
  }

  const serializedQuery = `?${qs.stringify({
    q: CrudQueryUtils.serializeQ(q),
    sort: ['-target_temptation'],
    offset: 0,
    limit: 1,
  })}`

  const targets = yield* call(io.api.recon.getTargets, serializedQuery)

  return targets
}

export function* _RECON_IPS_FOR_HOSTNAME_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const rules: Array<CrudQueryUtils.CrudRuleGroup | CrudQueryUtils.CrudRule> = [
    {
      ui_id: 'hostname_id',
      id: 'table.hostname_id',
      field: 'table.hostname_id',
      type: 'object',
      input: 'text',
      operator: 'equal',
      value: action.payload.id,
    },
  ]

  const q = {
    condition: 'AND',
    rules,
  }

  const query = {
    limit: action.payload.options.limit,
    offset: action.payload.options.offset,
    q: CrudQueryUtils.serializeQ(q),
    sort: action.payload.options.sort,
  }

  if (query.sort === '-target_temptation' || query.sort === 'target_temptation') {
    const ips = yield* call(io.api.recon.getIpsForHostname, `?${qs.stringify({ ...query, reversed_nulls: true })}`)

    return ips
  } else {
    const ips = yield* call(io.api.recon.getIpsForHostname, `?${qs.stringify(query)}`)

    return ips
  }
}

export function* _RECON_IPS_FOR_NETWORK_FETCH(io: MiddlewaresIO, action: AnyAction) {
  const hasUnaffiliation = yield* select(Store.GateSelectors.hasUnaffiliation)

  const _unaffiliateFilter: CrudQueryUtils.CrudRuleGroup = {
    ui_id: 'unaffiliated',
    condition: 'OR',
    rules: [
      {
        ui_id: 'show_unaffiliated',
        id: 'table.affiliation_state',
        field: 'table.affiliation_state',
        type: 'object',
        input: 'text',
        operator: 'not_equal',
        value: Codecs.AffiliationFieldValue.Unaffiliated,
      },
    ],
  }

  const _rules: Array<CrudQueryUtils.CrudRuleGroup | CrudQueryUtils.CrudRule> = [
    {
      ui_id: 'network_id',
      id: 'table.network_id',
      field: 'table.network_id',
      type: 'object',
      input: 'text',
      operator: 'equal',
      value: action.payload.id,
    },
  ]

  const q = {
    condition: 'AND',
    rules: _rules.concat(hasUnaffiliation ? [_unaffiliateFilter] : []),
  }

  const query = {
    limit: action.payload.options.limit,
    offset: action.payload.options.offset,
    q: CrudQueryUtils.serializeQ(q),
    sort: action.payload.options.sort,
  }

  if (query.sort === '-target_temptation' || query.sort === 'target_temptation') {
    const networks = yield* call(io.api.recon.getIpsForNetwork, `?${qs.stringify({ ...query, reversed_nulls: true })}`)

    return networks
  } else {
    const networks = yield* call(io.api.recon.getIpsForNetwork, `?${qs.stringify(query)}`)

    return networks
  }
}

export function* _RECON_MISSED_AFFILIATIONS_POST(
  io: MiddlewaresIO,
  action: _ReconActions.RECON_MISSED_AFFILIATIONS_POST,
) {
  if (action.payload.missedAffiliationsUpload) {
    const formData = new FormData()

    formData.append('file', action.payload.missedAffiliationsUpload)

    yield* call(io.api.artifacts.postMissedAffiliationsFile, formData)
  }

  const missedAffiliations = action.payload.missedAffiliationFields.filter((field: string) => !isEmpty(field))

  if (missedAffiliations.length > 0) {
    yield* call(io.api.artifacts.postMissedAffiliations, missedAffiliations)
  }
}

// ---------------------------------------------------------------------------

export function* _IPS_FETCH(io: MiddlewaresIO, action: _ReconActions.IPS_FETCH) {
  const serializedQuery = CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(action.payload))

  const result = yield* call(io.api.recon.getIps, serializedQuery)

  yield* put(Store.ReconActions.IPS_STORE_UPDATE(result))

  return result
}

export function* _IP_TOTALS_FETCH(io: MiddlewaresIO, action: _ReconActions.IP_TOTALS_FETCH) {
  const { total } = yield* call(io.api.recon.getIps, `?${action.payload}`)
  const { total: unaffiliatedTotal } = yield* call(io.api.recon.getIps, CrudQueryUtils.createUnaffiliatedQuery())

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: unaffiliatedTotal,
  }

  yield* put(Store.ReconActions.IP_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal,
  }
}

export function* _HOSTNAMES_FETCH(io: MiddlewaresIO, action: _ReconActions.HOSTNAMES_FETCH) {
  const serializedQuery = CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(action.payload))

  const result = yield* call(io.api.recon.getHostnames, serializedQuery)

  yield* put(Store.ReconActions.HOSTNAMES_STORE_UPDATE(result))

  return result
}

export function* _HOSTNAME_TOTALS_FETCH(io: MiddlewaresIO, action: _ReconActions.HOSTNAME_TOTALS_FETCH) {
  const { total } = yield* call(io.api.recon.getHostnames, `?${action.payload}`)
  const { total: unaffiliatedTotal } = yield* call(io.api.recon.getHostnames, CrudQueryUtils.createUnaffiliatedQuery())

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: unaffiliatedTotal,
  }

  yield* put(Store.ReconActions.HOSTNAME_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal,
  }
}

export function* _NETWORKS_FETCH(io: MiddlewaresIO, action: _ReconActions.NETWORKS_FETCH) {
  const serializedQuery = CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(action.payload))
  const result = yield* call(io.api.recon.getNetworks, serializedQuery)
  yield* put(Store.ReconActions.NETWORKS_STORE_UPDATE(result))

  return result
}

export function* _NETWORK_TOTALS_FETCH(io: MiddlewaresIO, action: _ReconActions.NETWORK_TOTALS_FETCH) {
  const { total } = yield* call(io.api.recon.getNetworks, `?${action.payload}`)
  const { total: unaffiliatedTotal } = yield* call(io.api.recon.getNetworks, CrudQueryUtils.createUnaffiliatedQuery())

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: unaffiliatedTotal,
  }

  yield* put(Store.ReconActions.NETWORK_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal,
  }
}

export function* _POLICY_FETCH(io: MiddlewaresIO, action: _PolicyActions.POLICY_FETCH) {
  const q = {
    condition: 'AND',
    rules: [
      {
        field: 'table.id',
        id: 'table.id',
        input: 'text',
        operator: 'equal',
        randoriOnly: true,
        type: 'string',
        ui_id: 'id',
        value: action.payload,
      },
    ],
  }

  const serializedQuery = `?${qs.stringify({ q: CrudQueryUtils.serializeQ(q) })}`

  const result = yield* call(io.api.recon.getPolicies, serializedQuery)

  const policy = head(result.data)

  if (isNotNil(policy)) {
    const modifiedPolicy = {
      ...policy,
      filter_data: QueryFilterUtils.getFilterHierarchy(policy.filter_data as CrudRuleGroup),
    }

    yield* put(Store.ReconActions.POLICY_STORE_UPDATE(modifiedPolicy))

    return modifiedPolicy
  } else {
    io.logger('_POLICY_FETCH returned nothing')

    return policy
  }
}

export function* _POLICIES_FETCH(io: MiddlewaresIO, action: _PolicyActions.POLICIES_FETCH) {
  const serializedQuery = CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(action.payload))

  // Unfortunately, it's necessary to migrate policies like this on a per-row basis

  const result = yield* call(io.api.recon.getPolicies, serializedQuery)

  const modifiedResult = {
    ...result,
    data: [
      ...result.data.map((row) => {
        return {
          ...row,
          filter_data: QueryFilterUtils.getFilterHierarchy(row.filter_data as CrudRuleGroup),
        }
      }),
    ],
  }

  yield* put(Store.ReconActions.POLICIES_STORE_UPDATE(modifiedResult))

  return modifiedResult
}

export function* _POLICIES_PATCH(io: MiddlewaresIO, action: _PolicyActions.POLICIES_PATCH) {
  const { id, ...payload } = action.payload

  const result = yield* call(io.api.recon.patchPolicy, id, payload)

  yield* put(Store.ReconActions.POLICY_STORE_UPDATE(result.data))

  return result
}

export function* _POLICIES_POST(io: MiddlewaresIO, action: _PolicyActions.POLICIES_POST) {
  const result = yield* call(io.api.recon.postPolicy, action.payload)

  return result
}

export function* _POLICY_TOTALS_FETCH(io: MiddlewaresIO, action: _PolicyActions.POLICY_TOTALS_FETCH) {
  const { total } = yield* call(io.api.recon.getPolicies, `?${action.payload}`)

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: 0,
    total: total,
  }

  yield* put(Store.ReconActions.POLICY_TOTALS_STORE_UPDATE(totals))

  return totals
}

export function* _SAVED_VIEWS_POST(io: MiddlewaresIO, action: _ReconActions.SAVED_VIEWS_POST) {
  const result = yield* call(io.api.recon.postSavedView, action.payload)

  // always rehydrate store after update since we do not get records back from updates
  const fetchAction: _ReconActions.SAVED_VIEWS_FETCH = {
    type: Store.ReconActions.TypeKeys.SAVED_VIEWS_FETCH,
    meta: action.meta,
    payload: '',
  }

  const fetchAllAction: _ReconActions.SAVED_VIEWS_FETCH_ALL = {
    type: Store.ReconActions.TypeKeys.SAVED_VIEWS_FETCH_ALL,
    meta: action.meta,
    payload: {
      limit: 100,
      offset: 0,
      sort: 'name',
    },
  }

  yield* call(_SAVED_VIEWS_FETCH, io, fetchAction)
  yield* call(_SAVED_VIEWS_FETCH_ALL, io, fetchAllAction)

  return result
}

export function* _SAVED_VIEWS_DELETE(io: MiddlewaresIO, action: _ReconActions.SAVED_VIEWS_DELETE) {
  const result = yield* call(io.api.recon.deleteSavedView, action.payload)

  const fetchAllAction: _ReconActions.SAVED_VIEWS_FETCH_ALL = {
    type: Store.ReconActions.TypeKeys.SAVED_VIEWS_FETCH_ALL,
    meta: action.meta,
    payload: {
      limit: 100,
      offset: 0,
      sort: 'name',
    },
  }

  yield* call(_SAVED_VIEWS_FETCH_ALL, io, fetchAllAction)

  return result
}

export function* _SAVED_VIEWS_PATCH(io: MiddlewaresIO, action: _ReconActions.SAVED_VIEWS_PATCH) {
  const result = yield* call(io.api.recon.patchSavedView, action.payload.id, action.payload.body)

  yield* put(Store.ReconActions.SAVED_VIEW_STORE_UPDATE(result.data))
  yield* put(Store.ReconActions.ALL_SAVED_VIEWS_STORE_SINGLE_UPDATE(result.data))

  const topSavedViews = yield* select(Store.ReconSelectors.selectTopAllSavedViews)

  if (topSavedViews.some((view) => view.id === action.payload.id)) {
    yield* put(Store.ReconActions.TOP_SAVED_VIEW_STORE_UPDATE(result.data))
  }

  return result
}

export function* _POLICY_DELETE(io: MiddlewaresIO, action: _PolicyActions.POLICY_DELETE) {
  const policy = yield* select(Store.ReconSelectors.selectPolicyById, { id: action.payload })

  const patchPayload: Codecs.PolicyPatchPayload = {
    data: omit(['created_at', 'creator_user_name', 'edited_at', 'editor_user_name', 'org_id', 'id', 'version'], {
      ...policy,
      is_deleted: true,
    }),
  }

  yield* call(io.api.recon.patchPolicy, action.payload, patchPayload)

  yield* put(Store.ReconActions.POLICY_DELETE_STORE_UPDATE(action.payload))
}

export function* _SAVED_VIEWS_FETCH(io: MiddlewaresIO, action: _ReconActions.SAVED_VIEWS_FETCH) {
  const serializedQuery = CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(action.payload))

  const result = yield* call(io.api.recon.getSavedViews, serializedQuery)

  yield* put(Store.ReconActions.SAVED_VIEWS_STORE_UPDATE(result))

  return result
}

export function* _SAVED_VIEW_FETCH(io: MiddlewaresIO, action: _ReconActions.SAVED_VIEW_FETCH) {
  const query = qs.stringify({
    limit: 1,
    q: {
      field: 'table.id',
      id: 'table.id',
      input: 'text',
      operator: 'equal',
      type: 'string',
      ui_id: 'id',
      value: action.payload,
    },
  })

  const result = yield* call(io.api.recon.getSavedViews, query)

  const [data] = result.data

  yield* put(Store.ReconActions.SAVED_VIEW_STORE_UPDATE(data))

  return result
}

export function* _SAVED_VIEWS_FETCH_ALL(io: MiddlewaresIO, action: _ReconActions.SAVED_VIEWS_FETCH_ALL) {
  const query = `?${qs.stringify(action.payload)}`

  const result = yield* call(io.api.recon.getSavedViews, query)

  yield* put(Store.ReconActions.ALL_SAVED_VIEWS_STORE_UPDATE(result))

  return result
}

export function* _SAVED_VIEWS_TOTALS_FETCH(io: MiddlewaresIO, action: _ReconActions.SAVED_VIEWS_TOTALS_FETCH) {
  const { total } = yield* call(io.api.recon.getSavedViews, `?${action.payload}`)
  const { total: unaffiliatedTotal } = yield* call(io.api.recon.getSavedViews, CrudQueryUtils.createUnaffiliatedQuery())

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: unaffiliatedTotal,
  }

  yield* put(Store.ReconActions.SAVED_VIEWS_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal,
  }
}

export function* _SERVICES_FETCH(io: MiddlewaresIO, action: _ReconActions.SERVICES_FETCH) {
  const serializedQuery = CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(action.payload))

  const result = yield* call(io.api.recon.getServices, serializedQuery)

  yield* put(Store.ReconActions.SERVICES_STORE_UPDATE(result))

  return result
}

export function* _SERVICE_TOTALS_FETCH(io: MiddlewaresIO, action: _ReconActions.SERVICE_TOTALS_FETCH) {
  const { total } = yield* call(io.api.recon.getServices, `?${action.payload}`)

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: 0,
  }

  yield* put(Store.ReconActions.SERVICE_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal: 0,
  }
}

export function* _SOCIAL_ENTITIES_FETCH(io: MiddlewaresIO, action: _ReconActions.SOCIAL_ENTITIES_FETCH) {
  const serializedQuery = CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(action.payload))
  const result = yield* call(io.api.recon.getSocialEntities, serializedQuery)

  yield* put(Store.ReconActions.SOCIAL_ENTITIES_STORE_UPDATE(result))

  // @TODO: Don't do this when called from outside of entity detail page
  const [data, ..._other] = result.data

  const guidanceContent = isNotNilOrEmpty(data) ? EntityDetailUtils.getGuidanceContent(data) : []

  yield* put(
    Store.UIActions.SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE({
      entityType: 'social',
      guidanceContent,
    }),
  )

  return result
}

export function* SOCIAL_ENTITY_TOTALS_FETCH(io: MiddlewaresIO, action: _ReconActions.SOCIAL_ENTITY_TOTALS_FETCH) {
  const { total } = yield* call(io.api.recon.getSocialEntities, `?${action.payload}`)

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: 0,
  }

  yield* put(Store.ReconActions.SOCIAL_ENTITIES_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal: 0,
  }
}

export function* _TARGET_CONTEXT_FETCH(io: MiddlewaresIO, action: _ReconActions.TARGET_CONTEXT_FETCH) {
  const result = yield* call(io.api.recon.getTargetContext, action.payload.serviceId, action.payload.query)

  return result
}

export function* _TARGET_FETCH(io: MiddlewaresIO, action: _ReconActions.TARGET_FETCH) {
  const result = yield* call(
    io.api.recon.getTargets,
    CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(`${action.payload}`)),
  )

  if (result.total > 1) {
    io.logger('_TARGET_FETCH returned more than 1 result')
  }

  const [target] = result.data

  yield* put(Store.ReconActions.TARGET_STORE_UPDATE(target))

  const guidanceContent = EntityDetailUtils.getGuidanceContent(target)

  yield* put(
    Store.UIActions.SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE({
      entityType: 'target',
      guidanceContent,
    }),
  )

  return target
}

export function* _TARGETS_FETCH(io: MiddlewaresIO, action: _ReconActions.TARGETS_FETCH) {
  const result = yield* call(
    io.api.recon.getTargets,
    CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(`${action.payload}&reversed_nulls=true`)),
  )

  const _policies = result.data.flatMap((target) => {
    return target.authorizing_policies
  })

  const policies = [...new Set(_policies)]
    .filter((policyId) => policyId !== 'MANUALLY-AUTHORIZED')
    .filter((policyId): policyId is string => {
      return isNotNil(policyId)
    })

  for (const policyId of policies) {
    const policy = yield* select(Store.ReconSelectors.selectAuthorizationPolicyById, { id: policyId })

    if (policy === undefined) {
      yield* put(Store.ReconActions.AUTHORIZATION_POLICY_FETCH(policyId, { success: noop, failure: noop }))
    }
  }

  // @TODO: Add `updateStore` boolean to payload

  if (action.meta.updateStore) {
    yield* put(Store.ReconActions.TARGETS_STORE_UPDATE(result))
  }

  return result
}

export function* _TARGETS_FOR_NETWORK_FETCH(io: MiddlewaresIO, action: _ReconActions.TARGETS_FOR_NETWORK_FETCH) {
  const q = {
    condition: 'AND',
    rules: [
      {
        field: 'table.ip',
        id: 'table.ip_str',
        input: 'text',
        operator: 'contained_by',
        randoriOnly: false,
        type: 'string',
        ui_id: 'ip_str',
        value: action.payload.value,
      },
    ],
  }

  const queryParams = qs.stringify({
    limit: action.payload.limit,
    offset: action.payload.offset,
    q: CrudQueryUtils.serializeQ(q),
  })

  const result = yield* call(
    io.api.recon.getTargets,
    CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(`${queryParams}&reversed_nulls=true`)),
  )

  yield* put(
    _ReconActions.TARGETS_FOR_ENTITY_STORE_UPDATE(
      result,
      action.payload.originatingEntityId,
      'network',
      action.payload.originatingEntityLensId,
    ),
  )

  return result
}

export function* _TARGET_TOTALS_FETCH(io: MiddlewaresIO, action: _ReconActions.TARGET_TOTALS_FETCH) {
  const { total } = yield* call(io.api.recon.getTargets, `?${action.payload}`)
  const { total: unaffiliatedTotal } = yield* call(io.api.recon.getTargets, CrudQueryUtils.createUnaffiliatedQuery())

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: unaffiliatedTotal,
  }

  yield* put(Store.ReconActions.TARGET_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal,
  }
}

export function* _DETECTIONS_FOR_TARGETS_FETCH(io: MiddlewaresIO, action: _ReconActions.DETECTIONS_FOR_TARGETS_FETCH) {
  const { id, updateStore, limit, offset } = action.payload

  if (updateStore) {
    yield* put(Store.ReconActions.DETECTIONS_STORE_RESET())
  }

  const q = {
    condition: 'AND',
    rules: [
      {
        id: 'table.target_id',
        field: 'table.target_id',
        type: 'object',
        input: 'text',
        operator: 'equal',
        value: id,
      },
    ],
  }

  const query = `?limit=${limit}&offset=${offset}&q=${CrudQueryUtils.serializeQ(q)}&sort=-detection_relevance&sort=ip`

  const result = yield* call(io.api.recon.getDetectionTargets, query)

  if (updateStore) {
    yield* put(Store.ReconActions.DETECTIONS_STORE_UPDATE(result))
  }

  return result
}

export function* _DETECTIONS_FOR_TARGETS_FETCH_P(
  io: MiddlewaresIO,
  action: _ReconActions.DETECTIONS_FOR_TARGETS_FETCH_P,
) {
  const { id, limit, offset } = action.payload.request

  const q = {
    condition: 'AND',
    rules: [
      {
        id: 'table.target_id',
        field: 'table.target_id',
        type: 'object',
        input: 'text',
        operator: 'equal',
        value: id,
      },
    ],
  }

  const query = `?limit=${limit}&offset=${offset}&q=${CrudQueryUtils.serializeQ(q)}&sort=-detection_relevance&sort=ip`

  const result = yield* call(io.api.recon.getDetectionTargets, query)

  yield* put(
    Store.ReconActions.DETECTIONS_STORE_UPDATE_P({
      response: result,
      targetId: action.payload.targetId,
    }),
  )

  return result
}

export function* _SCREENSHOTS_FOR_TARGETS_FETCH(
  io: MiddlewaresIO,
  action: _ReconActions.SCREENSHOTS_FOR_TARGETS_FETCH,
) {
  const { id, limit, offset } = action.payload

  const v2Q = {
    condition: 'AND',
    rules: [
      {
        id: 'table.consolidated_target__ids',
        field: 'table.consolidated_target__ids',
        type: 'array',
        input: 'text',
        operator: 'contains_element',
        value: id,
      },
      {
        id: 'table.artifact__screenshot_sha',
        field: 'table.artifact__screenshot_sha',
        input: 'text',
        operator: 'is_not_null',
        value: null,
      },
    ],
  }

  const v2Query = qs.stringify({
    limit,
    offset,
    q: CrudQueryUtils.serializeQ(v2Q),
    sort: ['detection_criteria__ip', 'id'],
  })

  const v2 = yield* call(io.api.detection.getDetections, get(`?${v2Query}`, QueryString))

  return v2
}

export function* _TARGETS_FOR_ENTITY_FETCH(io: MiddlewaresIO, action: _ReconActions.TARGETS_FOR_ENTITY_FETCH) {
  const idField = ReconSagaUtils.getIdFieldForTarget(action.payload.originatingEntityType)
  let _rules: Array<CrudQueryUtils.CrudRuleGroup | CrudQueryUtils.CrudRule> = []

  if (action.payload.originatingEntityType === 'service') {
    _rules = [
      {
        ui_id: idField,
        id: `table.${idField}`,
        field: `table.${idField}`,
        type: 'string',
        input: 'text',
        operator: 'equal',
        value: action.payload.originatingEntityId,
      },
      {
        ui_id: 'not_needed',
        id: 'table.lens_id',
        field: 'table.lens_id',
        type: 'string',
        input: 'text',
        operator: 'equal',
        value: action.payload.originatingEntityLensId,
      },
    ]
  } else {
    _rules = [
      {
        ui_id: idField,
        id: `table.${idField}`,
        field: `table.${idField}`,
        type: 'string',
        input: 'text',
        operator: 'equal',
        value: action.payload.originatingEntityId,
      },
    ]
  }

  const q = {
    condition: 'AND',
    rules: _rules,
  }

  const sort = ['-target_temptation', 'id']

  const query = action.payload.limit
    ? { q: CrudQueryUtils.serializeQ(q), sort, offset: action.payload.offset, limit: action.payload.limit }
    : { q: CrudQueryUtils.serializeQ(q), sort, offset: action.payload.offset }

  const serializedQuery = qs.stringify({ ...query, reversed_nulls: true })

  const _targets = yield* call(io.api.recon.getTargets, `?${serializedQuery}`)

  yield* put(
    _ReconActions.TARGETS_FOR_ENTITY_STORE_UPDATE(
      _targets,
      action.payload.originatingEntityId,
      action.payload.originatingEntityType,
      action.payload.originatingEntityLensId,
    ),
  )

  return _targets
}

export type MatchResponse = {
  hostname: Codecs.HostnamesResponse
  ip: Codecs.IpsResponse
  network: Codecs.NetworksResponse
  social: Codecs.SocialEntityResponse
  detection_target: Codecs.DetectionTargetResponse
}

export function* _POLICY_FETCH_MATCHING_ENTITIES(
  io: MiddlewaresIO,
  action: _PolicyActions.POLICY_FETCH_MATCHING_ENTITIES,
) {
  const { filter, entityTypes } = action.payload
  const query = `?limit=0&q=${CrudQueryUtils.serializeQ(filter)}`

  if (!entityTypes.length) {
    return []
  }

  const matches = entityTypes.reduce((acc, curr) => {
    const func = ReconSagaUtils.getQueryFnByEntityType(curr, io.api)

    acc[curr] = call(func, query)

    return acc
  }, {} as Record<EntityUtils.EntityType, any>)

  return yield* all(matches)
}

export function* _DETECTIONS_FETCH(io: MiddlewaresIO, action: _ReconActions.DETECTIONS_FETCH) {
  const result = yield* call(
    io.api.recon.getDetectionTargets,
    CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(`${action.payload}`)),
  )

  yield* put(Store.ReconActions.DETECTIONS_STORE_UPDATE(result))

  // @TODO: dont do this for other calls
  const [data, ..._other] = result.data

  // only try to get guidance content if data is not empty
  const guidanceContent = isNotNilOrEmpty(data) ? EntityDetailUtils.getGuidanceContent(data) : []

  yield* put(
    Store.UIActions.SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE({
      entityType: 'detection_target',
      guidanceContent,
    }),
  )

  return result
}

export function* _TOP_LEVEL_DETECTIONS_FETCH(io: MiddlewaresIO, action: _ReconActions.TOP_LEVEL_DETECTIONS_FETCH) {
  const result = yield* call(io.api.recon.getDetectionTargets, action.payload)

  const _policies = result.data.flatMap((detection) => {
    return detection.authorizing_policies
  })

  const policies = [...new Set(_policies)]
    .filter((policyId) => policyId !== 'MANUALLY-AUTHORIZED')
    .filter((policyId): policyId is string => {
      return isNotNil(policyId)
    })

  for (const policyId of policies) {
    const policy = yield* select(Store.ReconSelectors.selectAuthorizationPolicyById, { id: policyId })

    if (policy === undefined) {
      yield* put(Store.ReconActions.AUTHORIZATION_POLICY_FETCH(policyId, { success: noop, failure: noop }))
    }
  }

  if (action.meta.isGetSingle) {
    yield* put(Store.ReconActions.TOP_LEVEL_DETECTION_STORE_UPDATE(result))
  } else {
    yield* put(Store.ReconActions.TOP_LEVEL_DETECTIONS_STORE_UPDATE(result))
  }

  return result
}

export function* _TOP_LEVEL_DETECTION_TOTALS_FETCH(
  io: MiddlewaresIO,
  action: _ReconActions.TOP_LEVEL_DETECTION_TOTALS_FETCH,
) {
  const { total } = yield* call(io.api.recon.getDetectionTargets, action.payload)

  const { total: unaffiliatedTotal } = yield* call(
    io.api.recon.getDetectionTargets,
    CrudQueryUtils.createUnaffiliatedQuery(),
  )

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: unaffiliatedTotal,
  }

  yield* put(Store.ReconActions.TOP_LEVEL_DETECTION_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal,
  }
}

export function* _TIME_SERIES_ENTITY_STATS_FETCH(
  io: MiddlewaresIO,
  action: _ReconActions.TIME_SERIES_ENTITY_STATS_FETCH,
) {
  const getLatestStatQueryParameters = ReconSagaUtils.getStatsQueryParametersFactory(1, '-time', {
    range: action.payload.range,
    isPrioQuery: action.payload.isPrioQuery,
    isHighQuery: action.payload.isHighQuery,
  })

  const getOldestStatQueryParameters = ReconSagaUtils.getStatsQueryParametersFactory(1, 'time', {
    range: action.payload.range,
    isPrioQuery: action.payload.isPrioQuery,
    isHighQuery: action.payload.isHighQuery,
  })

  const getGraphDataPoints = ReconSagaUtils.getStatsQueryParametersFactory(1000, '-time', {
    range: action.payload.range,
    isPrioQuery: action.payload.isPrioQuery,
    isHighQuery: action.payload.isHighQuery,
  })

  const latestStatistics = yield* all({
    ['target']: call(io.api.recon.getStatistics, getLatestStatQueryParameters('target')),
    ['service']: call(io.api.recon.getStatistics, getLatestStatQueryParameters('service')),
    ['ip']: call(io.api.recon.getStatistics, getLatestStatQueryParameters('ip')),
    ['hostname']: call(io.api.recon.getStatistics, getLatestStatQueryParameters('hostname')),
    ['network']: call(io.api.recon.getStatistics, getLatestStatQueryParameters('network')),
  })

  const oldestStatistics = yield* all({
    ['target']: call(io.api.recon.getStatistics, getOldestStatQueryParameters('target')),
    ['service']: call(io.api.recon.getStatistics, getOldestStatQueryParameters('service')),
    ['ip']: call(io.api.recon.getStatistics, getOldestStatQueryParameters('ip')),
    ['hostname']: call(io.api.recon.getStatistics, getOldestStatQueryParameters('hostname')),
    ['network']: call(io.api.recon.getStatistics, getOldestStatQueryParameters('network')),
  })

  const graphStatistics = yield* call(io.api.recon.getStatistics, getGraphDataPoints(action.payload.entity))

  return {
    graphData: graphStatistics.data,

    targets: {
      stat: ReconSagaUtils.getStat(latestStatistics.target.data),
      difference: ReconSagaUtils.getStatDelta(latestStatistics.target.data, oldestStatistics.target.data),
    },

    services: {
      stat: ReconSagaUtils.getStat(latestStatistics.service.data),
      difference: ReconSagaUtils.getStatDelta(latestStatistics.service.data, oldestStatistics.service.data),
    },

    ips: {
      stat: ReconSagaUtils.getStat(latestStatistics.ip.data),
      difference: ReconSagaUtils.getStatDelta(latestStatistics.ip.data, oldestStatistics.ip.data),
    },

    hostnames: {
      stat: ReconSagaUtils.getStat(latestStatistics.hostname.data),
      difference: ReconSagaUtils.getStatDelta(latestStatistics.hostname.data, oldestStatistics.hostname.data),
    },

    networks: {
      stat: ReconSagaUtils.getStat(latestStatistics.network.data),
      difference: ReconSagaUtils.getStatDelta(latestStatistics.network.data, oldestStatistics.network.data),
    },
  }
}

export function* _PEER_GROUPS_FETCH(io: MiddlewaresIO, action: _ReconActions.PEER_GROUPS_FETCH) {
  // action PEER_GROUPS_FETCH takes an explicit `null` for no filter
  const query = isNotNil(action.payload) ? action.payload : ''

  const defaultQuery = qs.stringify({
    ...qs.parse(query),
    limit: 1000,
  })

  const result = yield* call(io.api.recon.getPeerGroups, `?${defaultQuery}`)

  yield* put(Store.ReconActions.PEER_GROUPS_STORE_UPDATE(result))

  return result
}

export function* _PEER_GROUP_TOTALS_FETCH(io: MiddlewaresIO, action: _ReconActions.PEER_GROUP_TOTALS_FETCH) {
  const { total } = yield* call(io.api.recon.getPeerGroups, action.payload)

  const { total: unaffiliatedTotal } = yield* call(
    io.api.recon.getDetectionTargets,
    CrudQueryUtils.createUnaffiliatedQuery(),
  )

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: unaffiliatedTotal,
  }

  yield* put(Store.ReconActions.PEER_GROUP_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal,
  }
}

export function* _PEER_GROUPS_POST(io: MiddlewaresIO, action: _ReconActions.PEER_GROUPS_POST) {
  const result = yield* call(io.api.recon.postPeerGroups, action.payload)

  return result
}

export function* _PEER_MAPS_FETCH(io: MiddlewaresIO, action: _ReconActions.PEER_MAPS_FETCH) {
  // action PEER_MAPS_FETCH takes an explicit `null` for no filter
  const query = isNotNil(action.payload) ? action.payload : ''

  const result = yield* call(io.api.recon.getPeerMaps, query)

  if (action.meta.updateStore) {
    yield* put(Store.ReconActions.PEER_MAPS_STORE_UPDATE(result))
  }

  return result
}

export function* _PEER_MAP_FETCH(io: MiddlewaresIO, action: _ReconActions.PEER_MAP_FETCH) {
  const result = yield* call(io.api.recon.getPeerMap, action.payload)

  yield* put(Store.ReconActions.PEER_MAP_STORE_UPDATE(result))

  return result
}

export function* _PEER_MAPS_POST(io: MiddlewaresIO, action: _ReconActions.PEER_MAPS_POST) {
  const {
    ids: [id],
  } = yield* call(io.api.recon.postPeerMaps, action.payload)

  const result = yield* call(io.api.recon.getPeerMap, id)

  yield* put(Store.ReconActions.PEER_MAP_STORE_UPDATE(result))

  return result
}

export function* _PEER_MAP_PATCH(io: MiddlewaresIO, action: _ReconActions.PEER_MAP_PATCH) {
  const result = yield* call(io.api.recon.patchPeerMap, action.payload.id, { data: action.payload.data })

  yield* put(Store.ReconActions.PEER_MAP_STORE_UPDATE(result))

  return result
}

export function* _PEER_MAP_UPSERT(_io: MiddlewaresIO, _action: _ReconActions.PEER_MAP_PATCH) {
  // noop
  return
}

export function* _TARGET_PATCH(io: MiddlewaresIO, action: _ReconActions.TARGET_PATCH) {
  const { id, ...restPayload } = action.payload

  const type = EntityUtils.getEndpointByType('target')

  // target ids are compound ids. this is unbelievably brittle. Bulk patch is
  // working elsewhere, so I assume it's being split somehow.
  const [_, targetId] = id.split(',')

  // it'd be *really* neat if /targets has a PATCH - i need to look for this
  const body = {
    data: restPayload,
    q: {
      condition: 'OR' as const,
      rules: [
        {
          field: 'table.id',
          id: 'table.id',
          input: 'text',
          operator: 'equal' as const,
          // type: 'object',
          value: targetId,
        },
      ],
    },
  }

  yield* call(io.api.recon.patchEntity, body, type)

  const q = CrudQueryUtils.serializeQ({
    condition: 'OR' as const,
    rules: [
      {
        field: 'table.id',
        id: 'table.id',
        input: 'text',
        operator: 'equal' as const,
        value: id,
      },
    ],
  })

  const { data } = yield* call(io.api.recon.getTargets, CrudQueryUtils.createQuery({ q }))

  const nextState = head(data)

  if (isNotNil(nextState)) {
    yield* put(Store.ReconActions.TARGET_STORE_UPDATE(nextState))
  }

  return nextState
}
