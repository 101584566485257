/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString } from '@/utilities/codec'

import * as actions from './event-log.actions'
import { normalizeEventLogs } from './event-log.schema'
import { eventLogSlice } from './event-log.slice'

// ---------------------------------------------------------------------------

export function* eventLogSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchEventLogs.toString(), deferredAction(_fetchEventLogs, io))
}

export function* _fetchEventLogs(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchEventLogs>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.eventLog.getEventLogs, queryString)

  const { count, offset, total } = response

  if (action.meta.persist) {
    yield* put(eventLogSlice.actions.replaceEventLogs(normalizeEventLogs(response.eventlogs)))

    yield* put(
      eventLogSlice.actions.replaceEventLogPagination({
        total,
        offset,
        count,
      }),
    )
  }

  return response
}
