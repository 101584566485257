/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { createSelector } from '@reduxjs/toolkit'

import * as Codecs from '@/codecs'
import type { AppState } from '@/store'
import { get, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

/**
 * Normalizes the structure of a source object for display in the summary table
 *
 * @param source - The source object to normalize
 *
 * @returns An object with varying fields due to implementation of Source parameters
 *
 */
export const normalizeSource = (source: Codecs.Source): Codecs.SourceNormalized => {
  const { id, parameters, ...rest } = source

  const normalizedSource = {
    id: get(id, UUID),
    ...rest,
    ...parameters,
  }

  return normalizedSource
}

export const selectSources = createSelector([(state: AppState) => state.source], (sourcesState) => {
  return sourcesState.result.map((uuid) => normalizeSource(sourcesState.entities.source[uuid]))
})

export const selectActiveSourcesPagination = createSelector([(state: AppState) => state.source], (sourcesState) => {
  return sourcesState.pagination.active
})

export const selectDeletedSourcesPagination = createSelector([(state: AppState) => state.source], (sourcesState) => {
  return sourcesState.pagination.deleted
})

export const selectSourcesPagination = createSelector([(state: AppState) => state.source], (sourcesState) => {
  return sourcesState.pagination
})

export const _selectSourceById = (state: AppState, props: { id: UUID }) => {
  const source = state.source.entities.source[props.id]

  return source
}

export const selectSourceById = createSelector([_selectSourceById], (source) => {
  if (isNotNil(source)) {
    return normalizeSource(source)
  }
})

export const selectSourceTotals = createSelector([(state: AppState) => state.source], (sourcesState) => {
  const sourceTotals = sourcesState.totals

  return sourceTotals
})
