/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createAction } from '@reduxjs/toolkit'
import { RouteComponentProps } from 'react-router-dom'
import { DeferredAction } from 'redux-saga-try-catch'

import { EntityName } from './event-log.constants'

// ---------------------------------------------------------------------------

export const fetchEventLogs = createAction(
  `${EntityName}/fetch-many`,
  (
    payload: RouteComponentProps['location']['search'],
    deferred: DeferredAction['meta']['deferred'],
    persist?: boolean,
  ) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)
