/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ExhaustiveError, RandoriEntityTypeError } from '@/utilities/r-error'

import type { EntityType } from '../entity-types'

export const getEndpointByType = (entityType: EntityType): string => {
  switch (entityType) {
    case 'action':
      return '/attack/api/v1/user/action'
    case 'activity_configuration':
      return '/api/v1/frontend/activity-configurations'
    case 'activity_instance':
      return '/recon/api/v1/activity-log'
    case 'artifact':
      return '/recon/api/v1/artifact'
    case 'asset':
      return '/recon/api/v2/asset'
    case 'bar':
      return '/access/api/v1/platform-event-view'
    case 'blueprint':
      return '/access/api/v1/platform-process-definition'
    case 'characteristicRule':
      return '/aggregator/api/v1/user/characteristic_rules_count'
    case 'detection_target':
      return '/recon/api/v1/all-detections-for-target'
    case 'event_log':
      return '/event-log/api/v1/eventLogs'
    case 'exception_policy':
      return '/api/v1/frontend/exception-policies'
    case 'globalArtifact':
      return '/aggregator/api/v1/user/artifacts'
    case 'globalHostname':
      return '/aggregator/api/v1/user/hostnames'
    case 'globalIps':
      return '/aggregator/api/v1/user/ips'
    case 'globalNetwork':
      return '/aggregator/api/v1/user/networks'
    case 'globalService':
      return '/aggregator/api/v1/user/services'
    case 'hostname':
      return '/recon/api/v1/hostname'
    case 'implant':
      return '/attack/api/v1/user/implants'
    case 'integrations':
      throw new RandoriEntityTypeError({ entityType })
    case 'ip':
      return '/recon/api/v1/ip'
    case 'network':
      return '/recon/api/v1/network'
    case 'organization':
      return '/auth/api/v1/organization'
    case 'peer':
      return '/recon/api/v1/peer'
    case 'poc':
      return '/recon/api/v1/social-entity'
    case 'policy':
      return '/recon/api/v1/policies'
    case 'redirector':
      return '/attack/api/v1/user/redirectors'
    case 'report':
      return '/recon/api/v1/reports'
    case 'runbook':
      return '/attack/api/v1/user/runbooks'
    case 'savedViews':
      return '/recon/api/v1/saved-views'
    case 'service':
      return '/recon/api/v1/service'
    case 'serviceRule':
      return '/aggregator/api/v1/user/service_rules_count'
    case 'serviceSuggestion':
      return '/aggregator/api/v1/user/suggestedservices'
    case 'social':
      return '/recon/api/v1/social-entity'
    case 'perspective':
      return '/perspectiveservice/api/v1/perspectives'
    case 'source':
      return '/perspectiveservice/api/v1/sources'
    case 'target':
      return '/recon/api/v1/target'
    case 'term':
      return '/aggregator/api/v1/user/terms'
    case 'topLevelDetection':
      return '/recon/api/v1/all-detections-for-target'
    case 'ipForHostname':
      return '/recon/api/v1/ip-for-hostname'
    case 'hostnameForIp':
      return '/recon/api/v1/hostname-for-ip'
    case 'ipForNetwork':
      return '/recon/api/v1/ip-for-network'
    case 'authorization_policy':
      return '/recon/api/v1/authorization-policies'
    case 'entity_for_activity_instance':
      throw new RandoriEntityTypeError({ entityType })
    case 'applicable_activity':
      throw new RandoriEntityTypeError({ entityType })
    case 'bdo_detection':
      return '/recon/api/v1/detection'

    default:
      throw new ExhaustiveError(entityType)
  }
}
