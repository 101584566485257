/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { toArray } from 'lodash/fp'
import { createSelector } from 'reselect'

import * as Codecs from '@/codecs'
import { isNotNilOrEmpty } from '@/utilities/is-not'
import * as EntityUtils from '@/utilities/r-entity'

import * as Store from '../../index'
import { NarrowedEntityTypes } from '../../reducers/ui/entity-detail.reducers'
import { isOpen } from '../../reducers/ui/inspect-pane.reducers'
import {
  hasAssignImpactStatus,
  hasAttackAuthorization,
  hasAttackOpportunistic,
  hasAuthorization,
  hasAuthorizationOnDetection,
  hasBart,
  hasEnableConfiguration,
  hasHOC,
  hasManageInternal,
  hasModifyData,
  hasReconStatus,
  hasRunbookEdit,
  hasServiceEdit,
  hasTags,
  hasTagsWrite,
  hasUnaffiliation,
  hasVulnerabilityValidation,
  staticFlags,
} from '../gate/gate.selectors'
import {
  markAuthorizationStateColumnAsInternal,
  removeAuthorizationColumn,
  removeDetectionAuthorizationStateColumn,
  removeDetectionAuthorizingPolicies,
} from './ui.selectors.utils'

// ---------------------------------------------------------------------------

const _getGuidancePane = (state: Store.AppState) => state.ui.guidancePane
const _getHocTemplate = (state: Store.AppState) => state.ui.hocTemplate
const _getInspectPane = (state: Store.AppState) => state.ui.inspectPane
const _getModal = (state: Store.AppState) => state.ui.modal
const _getReconSummary = (state: Store.AppState) => state.ui.reconSummary
const _getSlideout = (state: Store.AppState) => state.ui.slideout
const _getTTFactor = (state: Store.AppState, props: { factor: Codecs.TemptationFactor }) => state.ui.tt[props.factor]
const _getUI = (state: Store.AppState) => state.ui

const _getCharacteristic = (state: Store.AppState, props: { content: string }) => {
  return state.ui.characteristic[props.content]
}

const _getNavigationSection = (state: Store.AppState, props: { label: string; isActivePath: boolean }) => {
  return {
    state: state.ui.navigation[props.label],
    isActivePath: props.isActivePath,
  }
}

const _getEntityDetailNavItemState = (state: Store.AppState, props: { entityType: NarrowedEntityTypes }) => {
  return state.ui.entityDetailNav.items[props.entityType]
}

const _getEntityDetailNavActiveState = (state: Store.AppState, props: { entityType: NarrowedEntityTypes }) => {
  return state.ui.entityDetailNav.activeItem[props.entityType]
}

const _getEntityDetailGuidanceNavState = (state: Store.AppState, props: { entityType: NarrowedEntityTypes }) => {
  return state.ui.entityDetailNav.guidanceContent[props.entityType]
}

const _getEntityDetailContentRenderedState = (state: Store.AppState) => {
  return state.ui.entityDetailNav.contentRendered
}

const _getEntityTableColumns = (state: Store.AppState, props: { tableId: EntityUtils.EntityTypesTableSummary }) => {
  return state.ui.entityTable.columns[props.tableId]
}

const _getEntityTableColumnsForAll = (state: Store.AppState) => {
  return state.ui.entityTable.columns
}

// ---------------------------------------------------------------------------

export const isCharacteristicExpanded = createSelector([_getCharacteristic], (isExpanded) => isExpanded ?? false)
export const isFactorExpanded = createSelector([_getTTFactor], (isExpanded) => isExpanded ?? false)
export const isHocTemplateCopied = createSelector([_getHocTemplate], ({ template }) => isNotNilOrEmpty(template))
export const selectGuidancePane = createSelector([_getGuidancePane], (state) => state)
export const selectHocTemplate = createSelector([_getHocTemplate], ({ template }) => template)
export const selectInspectPane = createSelector([_getInspectPane], (state) => state)
export const selectModal = createSelector([_getModal], (modalState) => modalState)
export const selectReconSummaryVisibility = createSelector([_getReconSummary], ({ isVisible }) => isVisible)
export const selectSlideout = createSelector([_getSlideout], (slideoutState) => slideoutState)
export const selectUI = createSelector([_getUI], (ui) => ui)

export const selectEntityTableColumns = createSelector(
  [_getEntityTableColumns, hasAuthorization, hasAuthorizationOnDetection],
  (table, hasAuthorization, hasAuthorizationOnDetection) => {
    const columns = table.columns
      .filter(removeAuthorizationColumn({ hasAuthorization, hasAuthorizationOnDetection }))
      .filter(removeDetectionAuthorizationStateColumn({ hasAuthorization, hasAuthorizationOnDetection }))
      .filter(removeDetectionAuthorizingPolicies({ hasAuthorization, hasAuthorizationOnDetection }))

    const { meta } = table

    const _availableColumns = markAuthorizationStateColumnAsInternal(
      { hasAuthorization, hasAuthorizationOnDetection },
      meta.availableColumns,
    )

    return {
      ...table,
      meta: {
        ...meta,
        availableColumns: _availableColumns,
      },
      columns,
    }
  },
)

export const isNavigationExpanded = createSelector([_getNavigationSection], ({ state, isActivePath }) => {
  if (isActivePath) {
    return true
  } else {
    return state ?? false
  }
})

export const selectEntityDetailNavItems = createSelector([_getEntityDetailNavItemState], (state) => {
  return state
})

export const selectEntityDetailNavItemIds = createSelector([_getEntityDetailNavItemState], (state) => {
  return state.map((item) => item.id)
})

export const selectEntityDetailNavActiveItems = createSelector([_getEntityDetailNavActiveState], (state) => {
  return state
})

export const selectEntityDetailGuidanceNavItems = createSelector([_getEntityDetailGuidanceNavState], (state) => {
  return state
})

export const selectEntityDetailContentRenderedState = createSelector(
  [_getEntityDetailContentRenderedState],
  (state) => {
    return state
  },
)

export const selectEntityTableAvailableFields = createSelector([selectEntityTableColumns], (state) => {
  const available = toArray(state.meta.availableColumns)

  // There is a tight coupling between "columns to render" and "columns to filter". This creates an awkward condition
  // for detections, where we want to use our synthetic "authorized" column, but also need to filter on authorization
  // state. In order to do that, we have to "unmark" authorization_state from being private, but we don't want to allow
  // the user to add a duplicate column to the table.
  //
  // This is a byproduct of the madness that is filters + randapispec + columns. We need to *heavily* refactor this
  // system so that it is DRYed out, maintainable, and readable.
  if (state.meta.tableId === 'bdo_detection') {
    return available.filter(({ fieldName }) => fieldName !== 'authorization_state')
  }

  return available
})

export const selectInspectedEntityId = createSelector([_getInspectPane], (state) => {
  if (isOpen(state)) {
    return state.entity.entityId
  }
})

export const isInspectPaneOpen = createSelector([_getInspectPane], (state) => {
  return isOpen(state)
})

export const selectEntityTableColumnsForRender = createSelector([selectEntityTableColumns], ({ columns }) => columns)

export const selectColumnsForAllEntities = createSelector([_getEntityTableColumnsForAll], (tables) => tables)

export const selectExtDisplayFuncConfig = createSelector(
  [
    hasAssignImpactStatus,
    hasAttackAuthorization,
    hasAttackOpportunistic,
    hasAuthorization,
    hasAuthorizationOnDetection,
    hasBart,
    hasEnableConfiguration,
    hasHOC,
    hasManageInternal,
    hasModifyData,
    hasReconStatus,
    hasRunbookEdit,
    hasServiceEdit,
    hasTags,
    hasTagsWrite,
    hasUnaffiliation,
    hasVulnerabilityValidation,
    staticFlags,
  ],
  (
    hasAssignImpactStatus,
    hasAttackAuthorization,
    hasAttackOpportunistic,
    hasAuthorization,
    hasAuthorizationOnDetection,
    hasBart,
    hasEnableConfiguration,
    hasHOC,
    hasManageInternal,
    hasModifyData,
    hasReconStatus,
    hasRunbookEdit,
    hasServiceEdit,
    hasTags,
    hasTagsWrite,
    hasUnaffiliation,
    hasVulnerabilityValidation,
    staticFlags,
  ) => {
    return {
      hasAssignImpactStatus,
      hasAttackAuthorization,
      hasAttackOpportunistic,
      hasAuthorization,
      hasAuthorizationOnDetection,
      hasBart,
      hasEnableConfiguration,
      hasHoc: hasHOC,
      hasImpactScoreFlag: true,
      hasManageInternal,
      hasModifyData,
      hasRunbookEdit,
      hasServiceEdit,
      hasStatus: hasReconStatus,
      hasTags,
      hasTagsWrite,
      hasUnaffiliation,
      hasVulnerabilityValidation,
      staticFlags,
    }
  },
)
