/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ExhaustiveError, RandoriEntityTypeError } from '@/utilities/r-error'

import type { EntityType } from '../entity-types'

// ---------------------------------------------------------------------------

/**
 * Get url slug by entity type
 *
 * @param entityType - which entity do we need the slug for
 *
 * @returns the url slug for the given entity type
 *
 * @remarks
 *
 * This function is used to map url slugs to entity types. We need this for,
 * say, "/global", which is actually the peer entity type. Given how
 * sorting/etc are constructed, this would try and fetch a page call "/peer",
 * which would redirect to our fall-through page.
 */
export const getSlugByType = (entityType: EntityType): string => {
  switch (entityType) {
    case 'action':
      return 'actions'

    case 'activity_configuration':
      return 'activity-library'

    case 'activity_instance':
      return 'activity-log'

    case 'artifact':
      return 'artifacts'

    case 'asset':
      return 'assets'

    case 'bar':
      return 'bars'

    case 'blueprint':
      return 'blueprints'

    case 'characteristicRule':
      return 'characteristicrules'

    case 'detection_target':
      return 'detections'

    case 'exception_policy':
      return 'exception-policies'

    case 'globalArtifact':
      return 'artifacts'

    case 'globalHostname':
      return 'hostnames'

    case 'globalIps':
      return 'ips'

    case 'globalNetwork':
      return 'networks'

    case 'globalService':
      return 'services'

    case 'hostname':
      return 'hostnames'

    case 'implant':
      return 'implants'

    case 'integrations':
      return 'integrations'

    case 'ip':
      return 'ips'

    case 'network':
      return 'networks'

    case 'organization':
      return 'organizations'

    case 'peer':
      return 'global'

    case 'poc':
      return 'social'

    case 'policy':
      return 'policies'

    case 'redirector':
      return 'redirectors'

    case 'report':
      return 'reports'

    case 'runbook':
      return 'runbooks'

    case 'savedViews':
      return 'saved-views'

    case 'service':
      return 'services'

    case 'serviceRule':
      return 'servicerules'

    case 'serviceSuggestion':
      return 'suggestions'

    case 'social':
      return 'social'

    case 'event_log':
    case 'source':
      return 'sources'

    case 'target':
      return 'targets'

    case 'term':
      return 'terms'

    case 'topLevelDetection':
      return 'detections'

    case 'hostnameForIp':
      return 'hostnames'

    case 'ipForHostname':
      return 'ips'

    case 'ipForNetwork':
      return 'ips'

    case 'authorization_policy':
      return 'settings/authorization-policies'

    case 'bdo_detection':
      return 'detections'

    case 'applicable_activity':
    case 'entity_for_activity_instance':
    case 'perspective':
      throw new RandoriEntityTypeError({ entityType })

    default:
      throw new ExhaustiveError(entityType)
  }
}
