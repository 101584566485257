/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CrudQueryUtils from '@/utilities/crud-query'

import { ActivityConfiguration, ActivityConfigurationNormalized } from '../activity/activity-configuration.codecs'
import { ActivityInstance } from '../activity/activity-instance.codecs'
import { ApplicableActivity } from '../activity/applicable-activity.codecs'
import { Artifact, ReconArtifact, ReconArtifactResponse } from '../artifact.codecs'
import { Asset, AssetNormalized } from '../asset.codecs'
import { Bar, BarsResponse } from '../bar.codecs'
import { Blueprint, BlueprintsResponse } from '../blueprint.codecs'
import { CharacteristicRule, CharacteristicRuleResponse } from '../characteristic-rules.codecs'
import { DetectionP, DetectionV2 } from '../detection.codecs'
import { EventLog, EventLogNormalized } from '../event-log.codecs'
import { ExceptionPolicy, ExceptionPolicyNormalized } from '../exception-policy.codecs'
import { GlobalIp, GlobalIpsResponse } from '../global-ip.codecs'
import { GlobalNetwork } from '../global-network.codecs'
import { GlobalService } from '../global-service.codecs'
import { GlobalHostname, GlobalHostnameP, GlobalHostnameResponse } from '../hostname--aggregator.codecs'
import { Hostname, HostnameForIp, HostnameForIpP, HostnameP, HostnamesResponse } from '../hostname.codecs'
import { Implant, ImplantsResponse } from '../implants.codecs'
import { Ip, IpForHostname, IpForHostnameP, IpForNetwork, IpForNetworkP, IpP, IpsResponse } from '../ip.codecs'
import { Network, NetworkP, NetworksResponse } from '../network.codecs'
import { Organization } from '../organization.codecs'
import { PeerGroup, PeerGroupResponse } from '../peer-group.codecs'
import { PerspectiveV2, PerspectiveV2Normalized } from '../perspective.codecs'
import { Poc, PocResponse } from '../poc.codecs'
import { Policy } from '../policy.codecs'
import { Redirector, RedirectorsResponse } from '../redirector.codecs'
import { ASAReport, ExecSummaryReport } from '../reports'
import { Runbook, RunbooksResponse } from '../runbook.codecs'
import { SavedViews, SavedViewsResponse } from '../saved-views.codecs'
import { ServiceRuleMetadata } from '../service-rules-metadata.codecs'
import { ServiceRule, ServiceRuleResponse } from '../service-rules.codecs'
import { Service, ServiceP, ServicesResponse } from '../service.codecs'
import { SocialEntity } from '../social.codecs'
import { Source, SourceNormalized } from '../source.codecs'
import { ServiceSuggestionWithStats } from '../suggestion.codecs'
import { Detection, DetectionTarget, DetectionTargetResponse, TargetP } from '../target.codecs'
import { Term, TermResponse } from '../term.codecs'

// ---------------------------------------------------------------------------

export type Entity = t.TypeOf<typeof Entity>
export const Entity = t.union([
  ASAReport,
  ActivityConfiguration,
  ActivityConfigurationNormalized,
  ActivityInstance,
  ApplicableActivity,
  Artifact,
  Asset,
  AssetNormalized,
  Bar,
  Blueprint,
  CharacteristicRule,
  Detection,
  DetectionTarget,
  EventLog,
  EventLogNormalized,
  ExceptionPolicy,
  ExceptionPolicyNormalized,
  ExecSummaryReport,
  GlobalHostname,
  GlobalIp,
  GlobalNetwork,
  GlobalService,
  Hostname,
  HostnameForIp,
  Implant,
  Ip,
  IpForHostname,
  IpForNetwork,
  Network,
  Organization,
  PeerGroup,
  PerspectiveV2,
  PerspectiveV2Normalized,
  Poc,
  Policy,
  ReconArtifact,
  Redirector,
  Runbook,
  SavedViews,
  Service,
  ServiceRule,
  ServiceRuleMetadata,
  ServiceSuggestionWithStats,
  SocialEntity,
  Source,
  SourceNormalized,
  Term,

  // v2
  DetectionP,
  DetectionV2,
  GlobalHostnameP,
  HostnameForIpP,
  HostnameP,
  IpForHostnameP,
  IpForNetworkP,
  IpP,
  NetworkP,
  ServiceP,
  TargetP,
])

export type ReconEntity = t.TypeOf<typeof ReconEntity>
export const ReconEntity = t.union([DetectionTarget, HostnameP, IpP, NetworkP])

export const PatchEntityResponse = t.type({
  count: t.number,
})

export interface PatchEntityBody {
  operations: Array<{
    op: string
    path: string
    value: unknown
  }>

  q: {
    condition: string
    rules: CrudQueryUtils.QObject[]
  }
}

export type PatchEntityResponse = t.TypeOf<typeof PatchEntityResponse>

export type EntityPagination = t.TypeOf<typeof EntityPagination>
export const EntityPagination = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
})

export type EntityTotals = t.TypeOf<typeof EntityTotals>
export const EntityTotals = t.type({
  unfilteredTotal: t.number,
  unaffiliatedTotal: t.number,
})

export type HocEntityTotals = t.TypeOf<typeof HocEntityTotals>
export const HocEntityTotals = t.type({
  total: t.number,
  unfilteredTotal: t.number,
})

export type EntitiesResponse =
  | BarsResponse
  | BlueprintsResponse
  | CharacteristicRuleResponse
  | DetectionTargetResponse
  | GlobalHostnameResponse
  | GlobalIpsResponse
  | HostnamesResponse
  | ImplantsResponse
  | IpsResponse
  | NetworksResponse
  | PeerGroupResponse
  | PocResponse
  | ReconArtifactResponse
  | RedirectorsResponse
  | RunbooksResponse
  | SavedViewsResponse
  | ServiceRuleResponse
  | ServicesResponse
  | TermResponse
