/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { filter, isEmpty, isString, reduce } from 'lodash/fp'
import { createSelector } from 'reselect'

import { Preference } from '@/codecs'
import { AppState } from '@/store'
import * as ConfidenceUtils from '@/utilities/confidence'
import { isNotNil } from '@/utilities/is-not'
import * as EntityUtils from '@/utilities/r-entity'
import { ExhaustiveError, RandoriEntityTypeError } from '@/utilities/r-error'

import * as PrefSelectorUtils from './preferences.selectors.utils'

// ---------------------------------------------------------------------------

const _getSavedViews = (state: AppState) => state.recon.savedViews

const _getPreferenceByName = (state: AppState, config: { name: string }) => {
  return state.preferences.find((p) => p.preference === config.name)
}

const _getPreferences = (state: AppState) => {
  return state.preferences
}

const _getState = (state: AppState) => {
  return state
}

export const isPrefsHydrated = createSelector([_getPreferences], (state) => {
  return !isEmpty(state)
})

export const selectTTBoundaries = createSelector([_getPreferences], (_prefs) => {
  const prefs = _prefs as PrefSelectorUtils.TTBoundPreference[]

  const critical = PrefSelectorUtils.preferencesTemptationGetter(
    prefs,
    PrefSelectorUtils.TargetTempLevels.tt_level_critical,
  )
  const high = PrefSelectorUtils.preferencesTemptationGetter(prefs, PrefSelectorUtils.TargetTempLevels.tt_level_high)
  const medium = PrefSelectorUtils.preferencesTemptationGetter(
    prefs,
    PrefSelectorUtils.TargetTempLevels.tt_level_medium,
  )
  const low = PrefSelectorUtils.preferencesTemptationGetter(prefs, PrefSelectorUtils.TargetTempLevels.tt_level_low)

  return {
    critical: {
      upperBound: 100,
      lowerBound: critical.value as number,
    },

    high: {
      upperBound: (critical.value as number) - 1,
      lowerBound: high.value as number,
    },

    medium: {
      upperBound: (high.value as number) - 1,
      lowerBound: medium.value as number,
    },

    low: {
      upperBound: (medium.value as number) - 1,
      lowerBound: low.value as number,
    },
  } as PrefSelectorUtils.TTBoundaries
})

export const selectTTDefinitions = createSelector([_getPreferences], (prefs) => {
  const definitionPrefsLookup = [
    'tt_def_private_weakness',
    'tt_def_public_weakness',
    'tt_def_enumerability',
    'tt_def_criticality',
    'tt_def_post_exploit',
    'tt_def_research',
    'tt_def_applicability',
  ]

  const filterByDefs = filter((pref: Preference) => {
    return definitionPrefsLookup.includes(pref.preference as string)
  })

  const definitionPrefs: { [index: string]: Preference } = reduce(
    (acc: { [index: string]: Preference }, el: Preference) => {
      acc[el.preference as string] = el

      return acc
    },
    {},
    filterByDefs(prefs),
  )

  return {
    applicability: definitionPrefs['tt_def_applicability']['value'],
    criticality: definitionPrefs['tt_def_criticality']['value'],
    enumerability: definitionPrefs['tt_def_enumerability']['value'],
    post_exploit: definitionPrefs['tt_def_post_exploit']['value'],
    private_weakness: definitionPrefs['tt_def_private_weakness']['value'],
    public_weakness: definitionPrefs['tt_def_public_weakness']['value'],
    research: definitionPrefs['tt_def_research']['value'],
  } as { [index: string]: string }
})

export const selectTTDescriptions = createSelector([_getPreferences], (prefs) => {
  const descriptionPrefsLookup = [
    'tt_desc_applicability_1',
    'tt_desc_applicability_2',
    'tt_desc_criticality',
    'tt_desc_enumerability',
    'tt_desc_post_exploit',
    'tt_desc_private_weakness',
    'tt_desc_public_weakness',
    'tt_desc_research',

    'tt_desc_level_critical_1',
    'tt_desc_level_critical_2',
    'tt_desc_level_high_1',
    'tt_desc_level_high_2',
    'tt_desc_level_inreview_1',
    'tt_desc_level_inreview_2',
    'tt_desc_level_in_review_1',
    'tt_desc_level_in_review_2',
    'tt_desc_level_low_1',
    'tt_desc_level_low_2',
    'tt_desc_level_medium_1',
    'tt_desc_level_medium_2',
  ]

  const filterByDescs = filter((pref: Preference) => {
    return descriptionPrefsLookup.includes(pref.preference as string)
  })

  const descriptionPrefs: { [index: string]: Preference } = reduce(
    (acc: { [index: string]: Preference }, el: Preference) => {
      acc[el.preference as string] = el

      return acc
    },
    {},
    filterByDescs(prefs),
  )

  return {
    applicability1: descriptionPrefs['tt_desc_applicability_1']['value'],
    applicability2: descriptionPrefs['tt_desc_applicability_2']['value'],
    criticality: descriptionPrefs['tt_desc_criticality']['value'],
    enumerability: descriptionPrefs['tt_desc_enumerability']['value'],
    level_critical_1: descriptionPrefs['tt_desc_level_critical_1']['value'],
    level_critical_2: descriptionPrefs['tt_desc_level_critical_2']['value'],
    level_high_1: descriptionPrefs['tt_desc_level_high_1']['value'],
    level_high_2: descriptionPrefs['tt_desc_level_high_2']['value'],
    level_in_review_1: descriptionPrefs['tt_desc_level_inreview_1']['value'],
    level_in_review_2: descriptionPrefs['tt_desc_level_inreview_2']['value'],
    level_inreview_1: descriptionPrefs['tt_desc_level_inreview_1']['value'],
    level_inreview_2: descriptionPrefs['tt_desc_level_inreview_2']['value'],
    level_low_1: descriptionPrefs['tt_desc_level_low_1']['value'],
    level_low_2: descriptionPrefs['tt_desc_level_low_2']['value'],
    level_medium_1: descriptionPrefs['tt_desc_level_medium_1']['value'],
    level_medium_2: descriptionPrefs['tt_desc_level_medium_2']['value'],
    post_exploitation_potential: descriptionPrefs['tt_desc_post_exploit']['value'],
    private_weakness: descriptionPrefs['tt_desc_private_weakness']['value'],
    public_weakness: descriptionPrefs['tt_desc_public_weakness']['value'],
    research_potential: descriptionPrefs['tt_desc_research']['value'],
  } as { [index: string]: string }
})

export const selectConfidenceBoundaries = createSelector(
  [() => ConfidenceUtils.confidenceBoundaries],
  (confidenceBoundaries) => confidenceBoundaries,
)

export type ConfidenceBoundaries = ReturnType<typeof selectConfidenceBoundaries>

export type PriorityPreference = { max: number; low: number; medium: number; high: number }

export const selectPriorityPreferences = createSelector([_getPreferences], (prefs) => {
  const max = prefs.find((pref) => pref.preference === 'pri_score_range')
  const low = prefs.find((pref) => pref.preference === 'pri_score_low_max_range_perc')
  const medium = prefs.find((pref) => pref.preference === 'pri_score_medium_max_range_perc')
  const high = prefs.find((pref) => pref.preference === 'pri_score_high_max_range_perc')

  const maxValue = max === undefined ? 0 : max.value
  const lowValue = low === undefined ? 0 : low.value
  const medValue = medium === undefined ? 0 : medium.value
  const highValue = high === undefined ? 0 : high.value

  return {
    max: maxValue,
    low: lowValue,
    medium: medValue,
    high: highValue,
  } as PriorityPreference
})

export const selectDefaultViews = createSelector([_getPreferences, _getSavedViews], (prefs, savedViews) => {
  return prefs
    .filter((p) => {
      const savedViewPrefName = new RegExp('saved_view')

      if (isNotNil(p.preference)) {
        return savedViewPrefName.test(p.preference)
      } else {
        return false
      }
    })
    .map((p) => {
      return p.value as string
    })
    .map((savedViewId) => {
      return savedViews.entities.savedViews[savedViewId]
    })
    .filter(Boolean)
})

export const selectDefaultViewByName = createSelector([_getPreferenceByName, _getSavedViews], (pref, savedViews) => {
  if (isNotNil(pref) && isString(pref.value)) {
    const savedViewId = pref.value

    return savedViews.entities.savedViews[savedViewId]
  }
})

export const selectArtifactDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'artifact_saved_view' })
})

export const selectHostnameDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'hostname_saved_view' })
})

export const selectIpDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'ip_saved_view' })
})

export const selectTargetDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'target_saved_view' })
})

export const selectServiceDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'service_saved_view' })
})

export const selectNetworkDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'network_saved_view' })
})

export const selectSocialDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'social_saved_view' })
})

export const selectRunbookDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'runbook_saved_view' })
})

export const selectImplantDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'implant_saved_view' })
})

export const selectRedirectorDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'redirector_saved_view' })
})

export const selectActivityInstanceDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'activity_instance_saved_view' })
})

export const selectActivityConfigurationDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'activity_configuration_saved_view' })
})

export const selectAssetDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'asset_saved_view' })
})

export const selectNoSavedViewsDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: '' })
})

export const selectPoliciesDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'policies_saved_view' })
})

export const selectDetectionDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'top_level_detection_saved_view' })
})

export const selectReportDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'report_saved_view' })
})

export const selectAuthorizationPolicyDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'authorization_policy_saved_view' })
})

export const selectDetectionPDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'detection_saved_view' })
})

export const selectEventLogDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'event_log_saved_view' })
})

export const selectExceptionPolicyDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'exception_policy_saved_view' })
})

export const selectSourceDefaultView = createSelector([_getState], (state) => {
  return selectDefaultViewByName(state, { name: 'source_saved_view' })
})

export function getDefaultViewByEntityType(entityType: EntityUtils.EntityType) {
  switch (entityType) {
    case 'activity_instance':
      return selectActivityInstanceDefaultView
    case 'artifact':
      return selectNoSavedViewsDefaultView
    case 'asset':
      return selectAssetDefaultView
    case 'bar':
      return selectNoSavedViewsDefaultView
    case 'blueprint':
      return selectNoSavedViewsDefaultView
    case 'characteristicRule':
      return selectNoSavedViewsDefaultView
    case 'event_log':
      return selectEventLogDefaultView
    case 'exception_policy':
      return selectExceptionPolicyDefaultView
    case 'globalArtifact':
      return selectNoSavedViewsDefaultView
    case 'globalHostname':
      return selectNoSavedViewsDefaultView
    case 'globalIps':
      return selectNoSavedViewsDefaultView
    case 'globalNetwork':
      return selectNoSavedViewsDefaultView
    case 'globalService':
      return selectNoSavedViewsDefaultView
    case 'hostname':
      return selectHostnameDefaultView
    case 'implant':
      return selectImplantDefaultView
    case 'ip':
      return selectIpDefaultView
    case 'network':
      return selectNetworkDefaultView
    case 'organization':
      return selectNoSavedViewsDefaultView
    case 'peer':
      return selectNoSavedViewsDefaultView
    case 'poc':
      return selectNoSavedViewsDefaultView
    case 'policy':
      return selectPoliciesDefaultView
    case 'redirector':
      return selectRedirectorDefaultView
    case 'report':
      return selectNoSavedViewsDefaultView
    case 'runbook':
      return selectRunbookDefaultView
    case 'savedViews':
      return selectNoSavedViewsDefaultView
    case 'service':
      return selectServiceDefaultView
    case 'serviceRule':
      return selectNoSavedViewsDefaultView
    case 'serviceSuggestion':
      return selectNoSavedViewsDefaultView
    case 'social':
      return selectSocialDefaultView
    case 'source':
      return selectSourceDefaultView
    case 'target':
      return selectTargetDefaultView
    case 'term':
      return selectNoSavedViewsDefaultView
    case 'topLevelDetection':
      return selectDetectionDefaultView
    case 'authorization_policy':
      return selectAuthorizationPolicyDefaultView
    case 'bdo_detection':
      return selectDetectionPDefaultView

    case 'action':
    case 'activity_configuration':
    case 'applicable_activity':
    case 'detection_target':
    case 'entity_for_activity_instance':
    case 'hostnameForIp':
    case 'integrations':
    case 'ipForHostname':
    case 'ipForNetwork':
    case 'perspective':
      throw new RandoriEntityTypeError({ entityType })

    default:
      throw new ExhaustiveError(entityType)
  }
}

export function getPreferenceNameByEntityType(entityType: EntityUtils.EntityType) {
  switch (entityType) {
    case 'activity_configuration':
      return 'activity_configuration_saved_view'
    case 'activity_instance':
      return 'activity_instance_saved_view'
    case 'artifact':
      return 'artifact_saved_view'
    case 'bar':
      return 'bar_saved_view'
    case 'blueprint':
      return 'blueprint_saved_view'
    case 'characteristicRule':
      return 'characteristic_rule_saved_view'
    case 'exception_policy':
      return 'exception_policy_saved_view'
    case 'globalArtifact':
      return 'global_artifact_saved_view'
    case 'globalIps':
      return 'global_ip_saved_view'
    case 'globalHostname':
      return 'global_hostname_saved_view'
    case 'globalNetwork':
      return 'global_network_saved_view'
    case 'globalService':
      return 'global_service_saved_view'
    case 'hostname':
      return 'hostname_saved_view'
    case 'implant':
      return 'implant_saved_view'
    case 'ip':
      return 'ip_saved_view'
    case 'network':
      return 'network_saved_view'
    case 'organization':
      return 'organization_saved_view'
    case 'peer':
      return 'peer_saved_view'
    case 'poc':
      return 'poc_saved_view'
    case 'policy':
      return 'policy_saved_view'
    case 'redirector':
      return 'redirector_saved_view'
    case 'report':
      return 'report_saved_view'
    case 'runbook':
      return 'runbook_saved_view'
    case 'service':
      return 'service_saved_view'
    case 'serviceRule':
      return 'service_rule_saved_view'
    case 'serviceSuggestion':
      return 'service_suggestion_saved_view'
    case 'social':
      return 'social_saved_view'
    case 'target':
      return 'target_saved_view'
    case 'term':
      return 'term_saved_view'
    case 'topLevelDetection':
      return 'top_level_detection_saved_view'
    case 'authorization_policy':
      return 'authorization_policy_saved_view'
    case 'bdo_detection':
      return 'detection_saved_view'

    case 'action':
    case 'activity_configuration':
    case 'applicable_activity':
    case 'asset':
    case 'detection_target':
    case 'entity_for_activity_instance':
    case 'event_log':
    case 'hostnameForIp':
    case 'integrations':
    case 'ipForHostname':
    case 'ipForNetwork':
    case 'perspective':
    case 'savedViews':
    case 'source':
      throw new RandoriEntityTypeError({ entityType })

    default:
      throw new ExhaustiveError(entityType)
  }
}

export const selectHideViewGuidanceDefault = createSelector([_getPreferences], (prefs) => {
  const pref = prefs.find((p) => p.preference === 'view_guidance') || ({} as Preference)

  return Boolean(isNotNil(pref.value) ? pref.value : false)
})
