/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from '@reduxjs/toolkit'

import { EventLog, EventLogNormalized } from '@/codecs'
import type { AppState } from '@/store'
import { get, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

/**
 * Normalizes the structure of an event log object for display in the summary table
 *
 * @param eventLog - The event log object to normalize
 *
 * @returns An object with varying fields due to implementation of Event log body
 *
 */
export const normalizeEventLog = (eventLog: EventLog): EventLogNormalized => {
  const { id, body, ...rest } = eventLog

  const normalizedEventLog = {
    id: get(id, UUID),
    ...rest,
    ...body,
  }

  return normalizedEventLog
}

export const selectEventLogs = createSelector([(state: AppState) => state.eventLog], (eventLogsState) => {
  return eventLogsState.result.map((uuid) => normalizeEventLog(eventLogsState.entities.event_log[uuid]))
})

export const selectEventLogsPagination = createSelector([(state: AppState) => state.eventLog], (eventLogsState) => {
  return eventLogsState.pagination
})
