/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/**
 * This is the complete list of entity types in the `web-client`.
 *
 * @remarks
 * The entities defined here are specific to the context of `web-client` and do not match how entity
 * might be defined in other parts of the platform or within the context of a feature.
 *
 * `topLevelDetection` and `detection_target` both refer to detections and are used in different
 * contexts because XYZ.
 *
 * @see codecs/entity-types.codecs.ts for a codec validator:
 * getRequiredValue('target', Codecs.EntityType)
 */
export const EntityTypes = [
  'action',
  'activity_configuration',
  'activity_instance',
  'applicable_activity',
  'artifact',
  'asset',
  'authorization_policy', // synthetic
  'bar',
  'blueprint',
  'characteristicRule',
  'detection_target',
  'entity_for_activity_instance',
  'event_log',
  'exception_policy',
  'globalArtifact',
  'globalHostname',
  'globalIps',
  'globalNetwork',
  'globalService',
  'hostname',
  'hostnameForIp',
  'implant',
  'integrations',
  'ip',
  'ipForHostname',
  'ipForNetwork',
  'network',
  'organization',
  'peer',
  'perspective',
  'poc',
  'policy',
  'redirector',
  'report',
  'runbook',
  'savedViews',
  'service',
  'serviceRule',
  'serviceSuggestion',
  'social',
  'source',
  'target',
  'term',
  'topLevelDetection', // synthetic

  // New API objects will have an explicit "entity_type" field
  'bdo_detection',
] as const
export type EntityType = (typeof EntityTypes)[number]

/**
 * This is a subset of entities that have the summary table and pane implemented for the summary view.
 *
 * @remarks
 * We defined the type first to have a check that it overlaps `EntityType`
 * and then use the type when defining the array to help check for typos and values that are not in `EntityTypes`
 * `topLevelDetection` is not a part of this list. The components that use this type should do a conditional check
 * to use the `EntityType` `detection_target` in place of `topLevelDetection`
 */
export const EntityTypesTableSummary = [
  'activity_configuration',
  'activity_instance',
  'authorization_policy',
  'asset',
  'bdo_detection',
  'detection_target',
  'event_log',
  'exception_policy',
  'hostname',
  'implant',
  'ip',
  'network',
  'policy',
  'redirector',
  'report',
  'runbook',
  'savedViews',
  'service',
  'social',
  'source',
  'target',
] as const
export type EntityTypesTableSummary = (typeof EntityTypesTableSummary)[number]

export const EntityTypesTableSummaryValidator = () =>
  EntityTypesTableSummary.reduce((isLegit, member) => {
    const check = EntityTypes.includes(member)

    return check && isLegit
  }, true)

export const isEntityTypesTableSummary = (type: string): type is EntityTypesTableSummary => {
  return EntityTypes.includes(type as EntityTypesTableSummary)
}

/**
 * This is a subset of entities that have a summary table or list in the UI.
 *
 * @remarks
 * We defined the type first to have a check that it overlaps `EntityType`
 * and then use the type when defining the array to help check for typos and values that are not in `EntityTypes`
 */
export const EntityTypesSummary = [
  ...EntityTypesTableSummary,
  'bar',
  'blueprint',
  'characteristicRule',
  'globalArtifact',
  'globalHostname',
  'globalIps',
  'globalNetwork',
  'globalService',
  'organization',
  'poc',
  'serviceRule',
  'serviceSuggestion',
  'term',
  'topLevelDetection',
] as const
export type EntityTypesSummary = (typeof EntityTypesSummary)[number]

export const EntityTypesSummaryValidator = () =>
  EntityTypesSummary.reduce((isLegit, member) => {
    const check = EntityTypes.includes(member)

    return check && isLegit
  }, true)

/**
 * @see Codecs.AuthoritativeEntityType
 **/
export const AuthoritativeEntityTypes = [
  'org_asset',
  'org_hostname',
  'org_ip',
  'org_network',
  'org_term',
  'org_poc',
  'org_detection',
  'org_target',
] as const
export type AuthoritativeEntityTypes = (typeof AuthoritativeEntityTypes)[number]

/**
 * The list of possible values for the `entity_type` field returned by API objects
 *
 * @remarks
 * New APIs should have a discriminate field, called `entity_type`.
 */
export const EntityTypesP = ['bdo_detection'] as const
export type EntityTypeP = (typeof EntityTypesP)[number]
