/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EntityPagination } from '@/codecs'

import { EntityName, EventLogState, initialState } from './event-log.constants'
import { normalizeEventLogs } from './event-log.schema'

// ---------------------------------------------------------------------------

export const eventLogSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    replaceEventLogs: (state, action: PayloadAction<ReturnType<typeof normalizeEventLogs>>) => {
      state.result = action.payload.result

      state.entities = {
        event_log: {
          ...state.entities.event_log,
          ...action.payload.entities.event_log,
        },
      }
    },

    replaceEventLogTotals: (state, action: PayloadAction<EventLogState['totals']>) => {
      state.totals = action.payload
    },

    replaceEventLogPagination: (state, action: PayloadAction<EntityPagination>) => {
      state.pagination = action.payload
    },
  },
})
