/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ExhaustiveError, RandoriEntityTypeError } from '@/utilities/r-error'

import type { EntityType } from '../entity-types'

export const getDisplayByType = (entityType: EntityType): string => {
  switch (entityType) {
    case 'action':
      return 'Action'
    case 'activity_configuration':
      return 'Activity Configuration'
    case 'activity_instance':
      return 'Activity'
    case 'artifact':
      return 'Artifact'
    case 'asset':
      return 'Asset'
    case 'bar':
      return 'Bart Request (Global)'
    case 'blueprint':
      return 'Runbook Blueprint (Global)'
    case 'characteristicRule':
      return 'Characteristic Rule'
    case 'detection_target':
      return 'Detection'
    case 'exception_policy':
      return 'Exception Policy'
    case 'globalArtifact':
      return 'Artifact'
    case 'globalHostname':
      return 'Hostname'
    case 'globalIps':
      return 'IP Address'
    case 'globalNetwork':
      return 'Network'
    case 'globalService':
      return 'Service'
    case 'hostname':
      return 'Hostname'
    case 'implant':
      return 'Implant'
    case 'integrations':
      return 'Integration'
    case 'ip':
      return 'IP Address'
    case 'network':
      return 'Network'
    case 'organization':
      return 'Organization'
    case 'peer':
      return 'Peer Group'
    case 'poc':
      return 'POC'
    case 'policy':
      return 'Policy'
    case 'redirector':
      return 'Redirector'
    case 'report':
      return 'Report'
    case 'runbook':
      return 'Runbook'
    case 'savedViews':
      return 'Saved View'
    case 'service':
      return 'Service'
    case 'serviceRule':
      return 'Service Rule'
    case 'serviceSuggestion':
      return 'Suggestion'
    case 'social':
      return 'Social'
    case 'source':
      return 'Source'
    case 'target':
      return 'Target'
    case 'term':
      return 'Term'
    case 'topLevelDetection':
      return 'Detection'
    case 'ipForHostname':
      return 'IP Address'
    case 'ipForNetwork':
      return 'IP Address'
    case 'hostnameForIp':
      return 'Hostname'
    case 'authorization_policy':
      return 'Authorization Policy'
    case 'applicable_activity':
    case 'entity_for_activity_instance':
    case 'event_log':
    case 'perspective':
      throw new RandoriEntityTypeError({ entityType })
    case 'bdo_detection':
      return 'Detection'

    default:
      throw new ExhaustiveError(entityType)
  }
}
