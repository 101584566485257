/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNil } from 'ramda'
import { createSelector, defaultMemoize } from 'reselect'

import { AppState } from '@/store'
import { Flags } from '@/utilities/flags'
import { isNotNil } from '@/utilities/is-not'

import { selectFeatures } from '../organization/organization.selectors'
import { selectPermissions } from '../session/session.selectors'

// ---------------------------------------------------------------------------

const _getFlags = (state: AppState) => {
  return state.ui.flags
}

export const hasNvd = createSelector([_getFlags], (_flags) => true)
export const staticFlags = createSelector([_getFlags], (flags) => flags)
export const selectStaticFlags = staticFlags
export const hasTechCategories = createSelector([_getFlags], (_flags) => true)
export const hasRead = createSelector([selectPermissions], (permissions) => permissions.includes('read-data'))
export const hasManage = createSelector([selectPermissions], (permissions) => permissions.includes('manage'))
export const hasManageInternal = createSelector([selectPermissions], (permissions) =>
  permissions.includes('manage-internal'),
)

export const isObserver = hasRead
export const isManager = hasManage

export const hasFeatureFlag = createSelector([selectFeatures], (features) =>
  defaultMemoize((feature: string) => {
    return !isNil(features[feature])
  }),
)

export const hasStaticFlag = createSelector([selectStaticFlags], (flags) =>
  defaultMemoize((flag: keyof typeof Flags) => {
    return flags[flag]
  }),
)

export const hasPermission = createSelector([selectPermissions], (permissions) =>
  defaultMemoize((permission: string) => {
    return permissions.includes(permission)
  }),
)

export const hasAttackView = createSelector([hasRead, selectFeatures], (hasRead, features) => {
  return hasRead && !isNil(features.ATTACK)
})

export const hasTagsView = createSelector([hasRead, selectFeatures], (hasRead, features) => {
  return hasRead && !isNil(features.TAGS)
})

export const hasInterestingTagsView = createSelector([hasRead, selectFeatures], (hasRead, features) => {
  return hasRead && !isNil(features.AUTO_TAG)
})

export const hasTagsWrite = createSelector([hasTagsView, selectPermissions], (hasTagsView, permissions) => {
  return hasTagsView && permissions.includes('tag-write')
})

export const hasClaimsView = createSelector([hasTagsView, selectFeatures], (hasTagsView, features) => {
  return hasTagsView && !isNil(features.CLAIMS)
})

export const hasClaimsWrite = createSelector([hasClaimsView, selectPermissions], (hasClaimsView, permissions) => {
  return hasClaimsView && permissions.includes('tag-write') && permissions.includes('claim-entity')
})

export const hasBulkTagsWrite = createSelector([hasTagsWrite, selectFeatures], (hasTagsWrite, features) => {
  return hasTagsWrite && !isNil(features.BULK_TAGGING)
})

export const hasUserSettings = createSelector([hasRead, selectFeatures], (hasRead, features) => {
  return hasRead && !isNil(features.USER_SETTINGS)
})

export const hasModifyData = createSelector([selectPermissions], (permissions) => {
  return permissions.includes('modify-data')
})

export const hasApiAccess = createSelector([selectFeatures, selectPermissions], (features, permissions) => {
  return !isNil(features.API_ACCESS) && permissions.includes('manage')
})

export const hasArtifacts = createSelector([selectFeatures], (features) => {
  return !isNil(features.ARTIFACTS)
})

export const hasSSTV = createSelector([selectFeatures], (features) => {
  return !isNil(features.SSTV_DASHBOARD)
})

export const hasSocialRecon = createSelector([selectFeatures], (features) => {
  return !isNil(features.SOCIAL_RECON)
})

export const hasHDYFT = createSelector([selectFeatures], (features) => {
  return !isNil(features.HDYFT)
})

export const hasUnaffiliation = createSelector([selectFeatures], (features) => {
  return !isNil(features.UNAFFILIATION)
})

export const hasUnaffiliationWrite = createSelector(
  [hasUnaffiliation, selectPermissions],
  (hasUnaffiliation, permissions) => {
    return hasUnaffiliation && permissions.includes('unaffiliate-entity')
  },
)

export const hasMissedAffiliation = createSelector([selectFeatures], (features) => {
  return !isNil(features.MISSED_AFFILIATION)
})

export const hasMissedAffiliationWrite = createSelector(
  [hasMissedAffiliation, selectPermissions],
  (hasMissedAffiliation, permissions) => {
    return hasMissedAffiliation && permissions.includes('modify-data')
  },
)

export const hasBartView = createSelector([selectPermissions, selectFeatures], (permissions, features) => {
  return permissions.includes('attack-view') && !isNil(features.BART)
})

export const hasBart = createSelector([selectFeatures], (features) => {
  return !isNil(features.BART)
})

export const hasAttackAuthorization = createSelector([selectPermissions, selectFeatures], (permissions, features) => {
  return permissions.includes('execute-attack') && !isNil(features.ATTACK_AUTHORIZATION)
})

export const hasAttackOpportunistic = createSelector([selectFeatures], (features) => {
  return !isNil(features.ATTACK_OPPORTUNISTIC)
})


export const hasAuthorization = createSelector([selectPermissions, selectFeatures], (permissions, features) => {
  return (
    permissions.includes('execute-attack') &&
    (!isNil(features.ATTACK_AUTHORIZATION) || !isNil(features.VULNERABILITY_VALIDATION))
  )
})

export const hasReconStatus = createSelector([selectPermissions, selectFeatures], (permissions, features) => {
  return permissions.includes('read-data') && !isNil(features.STATUS)
})

export const hasStatusWrite = createSelector([hasReconStatus, selectPermissions], (hasReconStatus, permissions) => {
  return hasReconStatus && permissions.includes('modify-data')
})

export const hasHOC = createSelector([selectPermissions, selectFeatures], (permissions, _features) => {
  return permissions.includes('hoc-access')
})

export const hasBackend = createSelector([selectPermissions, selectFeatures], (permissions, _features) => {
  return permissions.includes('backend')
})

export const hasServiceEdit = createSelector([selectPermissions, selectFeatures], (permissions, _features) => {
  return permissions.includes('score-services')
})

export const hasPriority = createSelector([_getFlags], (_flags) => {
  return true
})

export const hasPolicies = createSelector([selectPermissions, selectFeatures], (_a, features) => {
  return !isNil(features.POLICY)
})

export const hasPolicyWrite = createSelector([hasPolicies, selectPermissions], (hasPolicies, permissions) => {
  return hasPolicies && permissions.includes('modify-data')
})

export const hasSso = createSelector([selectPermissions, selectFeatures], (permissions, features) => {
  return permissions.includes('manage') && isNotNil(features.SSO)
})

export const hasPlatformSupport = createSelector([selectPermissions, staticFlags], (permissions, flags) => {
  return permissions.includes('platform-support') && !flags.hidePlatformSupport
})

export const hasWorkatoAccess = createSelector([selectPermissions, selectFeatures], (_a, features) => {
  return !isNil(features.WORKATO_INTEGRATION)
})

export const hasRunbookEdit = createSelector([selectPermissions, selectFeatures], (permissions, _features) => {
  return permissions.includes('hoc-access')
})

export const hasIntegrationsWrite = createSelector([selectPermissions], (permissions) => {
  return permissions.includes('integration-write')
})

export const hasSavedViews = createSelector([selectFeatures], (features) => {
  return !isNil(features.SAVED_VIEWS)
})

export const hasAssignImpactStatus = createSelector([selectFeatures], (features) => {
  return !isNil(features.ASSIGN_IMPACT_AND_STATUS)
})

export const hasVulnerabilityValidation = createSelector([selectFeatures], (features) => {
  return !isNil(features.VULNERABILITY_VALIDATION)
})

export const hasTags = createSelector([selectFeatures], (features) => {
  return !isNil(features.TAGS)
})

export const hasAuthorizationOnDetection = createSelector([staticFlags], (_flags) => {
  return true
})

export const hasAuthorizationPolicies = createSelector(
  [selectPermissions, selectFeatures, staticFlags, hasAuthorizationOnDetection],
  (perms, features, _flags, hasAuthorization) => {
    const hasPolicy = !isNil(features.POLICY)
    const hasAnAuthorizationFeature = !isNil(features.VULNERABILITY_VALIDATION) || !isNil(features.BART)
    const hasAuthorizationWrite = perms.includes('execute-attack') //I thought only ppl with edit capability could see the page

    return hasPolicy && hasAuthorization && hasAnAuthorizationFeature && hasAuthorizationWrite
  },
)

export const hasAuthorizationPolicyWrite = createSelector(
  [selectPermissions, selectFeatures, staticFlags, hasAuthorizationOnDetection],
  (perms, features, _flags, hasAuthorization) => {
    const hasPolicy = !isNil(features.POLICY)
    const hasAnAuthorizationFeature = !isNil(features.VULNERABILITY_VALIDATION) || !isNil(features.BART)
    const hasAuthoriationWrite = perms.includes('execute-attack')

    return hasPolicy && hasAuthorization && hasAnAuthorizationFeature && hasAuthoriationWrite
  },
)

// @TODO: do we need `hasAuthorizationWrite`
//
// What's the difference between `ATTACK_AUTHORIZATION` and `BART`?
export const hasAuthorizationWrite = createSelector([selectPermissions, selectFeatures], (permissions, features) => {
  return permissions.includes('execute-attack') && (!isNil(features.BART) || !isNil(features.VULNERABILITY_VALIDATION))
})

export const hasEnableConfiguration = hasAuthorizationWrite

// Used only to determine if user can view applicable activities (not necessarily execute)
// Note: 'modify-data' permission is mapped to 'write-data' permission from the BE
// see: https://github.com/RandoriDev/randori-platform-common/blob/main/src/randori/platform/common/auth/enums.py#L452
export const hasApplicableActivitiesView = createSelector([selectPermissions], (permissions) => {
  return permissions.includes('modify-data') || permissions.includes('execute-attack')
})

export const hasAddToQueue = createSelector(
  [hasApplicableActivitiesView, selectFeatures],
  (hasApplicableActivitiesView, features) => {
    return hasApplicableActivitiesView && !isNil(features.VULNERABILITY_VALIDATION)
  },
)

export const hasAddInternalNetwork = createSelector(
  [selectPermissions, selectFeatures, staticFlags],
  (permissions, _features, flags) => {
    return flags.addInternalNetwork && permissions.includes('manage-internal')
  },
)
