/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { EntityPagination, EventLog } from '@/codecs'

// ---------------------------------------------------------------------------

export type EventLogState = {
  pagination: EntityPagination
  result: string[]
  totals: { unfiltered: number; unaffiliated: number }

  entities: {
    event_log: Record<string, EventLog>
  }
}

export type EntityName = 'event_log'
export const EntityName: EntityName = 'event_log'

export const initialState: EventLogState = {
  pagination: { count: 0, offset: 0, total: 0 },
  result: [],
  totals: { unfiltered: 0, unaffiliated: 0 },

  entities: {
    event_log: {},
  },
}
