/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { EventLog } from './event-log'

// ---------------------------------------------------------------------------

export type EventLogsResponse = t.TypeOf<typeof EventLogsResponse>
export const EventLogsResponse = t.type({
  eventlogs: t.array(EventLog),
  count: t.number,
  offset: t.number,
  total: t.number,
})
